<template>
  <f7-page name="home" class="fondo-pantalla-completa">
    <Navbardop slot="fixed" nav-title="Detalle Trabajador" />
    <div class="texto-grande padding-left">MOPECO - Parcelas</div>
    <SideMenuMopeco></SideMenuMopeco>
  </f7-page>
</template>

<script>

import SideMenuMopeco from '@/components/SideMenuMopeco.vue'
export default {
 name: 'parcelas',
 components :{
   SideMenuMopeco
 }

};
</script>
<style>
/* #app2 {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
