<template>
  <f7-list menu-list media-list>
    
    <f7-list-item
      link
      title="Home"
      subtitle=""
      :selected="selectedMedia === 'Home'"
      @click="() => (selectedMedia = 'Home')"
    >
      <template #media>
        <f7-icon md="material:home" aurora="f7:house_fill" ios="f7:house_fill" />
      </template>
    </f7-list-item>
    <f7-list-item
      link
      title="Perfil"
      subtitle=""
      :selected="selectedMedia === 'Perfil'"
      @click="() => (selectedMedia = 'Perfil')"
    >
      <template #media>
        <f7-icon md="material:person" aurora="f7:person_fill" ios="f7:person_fill" />
      </template>
    </f7-list-item>
    <f7-list-item
      link
      title="Configuracion"
      subtitle=""
      :selected="selectedMedia === 'Configuracion'"
      @click="() => (selectedMedia = 'Configuracion')"
    >
      <template #media>
        <f7-icon md="material:settings" aurora="f7:gear_alt_fill" ios="f7:gear_alt_fill" />
      </template>
    </f7-list-item>

       <f7-list-item
      link
      title="Enlace"
      subtitle=""
      :selected="selectedMedia === 'Enlace'"
      @click="() => (selectedMedia = 'Enlace')"
    >
      <template #media>
        <f7-icon md="material:add_circle" aurora="f7:add_circle" ios="f7:add_circle" />
      </template>
    </f7-list-item>
  </f7-list>


  

</template>
<script>
import { ref } from 'vue';

export default {
  
};


</script>

<style scoped>

</style>