<<template>
	<f7-page name="caracteristicasBomba" class="fondo-pantalla-completa">
		<Navbardop
		slot="fixed"
		nav-title="Detalle Trabajador"
		/>
		<!--DOS BLOQUES INTERMEDIOS-->
		<f7-row>
			<div class="col">
				<f7-block-title class="margen-izquierdo-standar">{{$t('TheoreticalCharacteristics')}}</f7-block-title>
				<f7-block strong class="caja caja-coste-energia margen-izquierdo-standar">
					<f7-row>
						<f7-col style="padding-top:20px;">
							<f7-block-title>{{ $t('FlowText') }}</f7-block-title>
							<f7-block-title>{{ $t('PowerText') }}</f7-block-title>
							<f7-block-title>{{ $t('DeliveryPipeDiameter') }}</f7-block-title>
							<f7-block-title>{{ $t('SecondaryDiameter') }}</f7-block-title>
						</f7-col>

						<f7-col>
							<f7-list inline-labels no-hairlines-md style="margin-top:20px;">
								<f7-list-input type="number" name="caudal" :value="caracteristicasBomba.caudal" readonly></f7-list-input>
								<f7-list-input type="number" name="potencia" :value="caracteristicasBomba.potencia" readonly></f7-list-input>
								<f7-list-input type="number"  name="diametro_tuberia_impulsion" :value="caracteristicasBomba.diametroTuberiaBombeo" readonly></f7-list-input>
								<f7-list-input type="number" name="diametro_secundario" :value="caracteristicasBomba.diametroTuberiaSecundaria" readonly></f7-list-input>
							</f7-list>
						</f7-col>


					</f7-row>
				</f7-block>

			</div class="col">


			<div class="col">
				<f7-block-title class="margen-izquierdo-standar">{{ $t('CommercialSteelDiameters') }}</f7-block-title>
				<f7-block strong class="caja caja-diametros-comerciales">
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(43.3)">DN40(43.3)</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(106.8)">DN150(160.8)</f7-button>
						</f7-col>
					</f7-row>
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(55.3)">DN50(55.3)</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(185.7)">DN175(185.7)</f7-button>
						</f7-col>
					</f7-row>
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(68)">DN65(68)</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(210.1)">DN224(210.1)</f7-button>
						</f7-col>
					</f7-row>
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(82.9)">DN80(82.9)</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(233.5)">DN225(233.5)</f7-button>
						</f7-col>
					</f7-row>
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(43.3)">DN100(107.8)</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(261.1)">DN250(261.1)</f7-button>
						</f7-col>
					</f7-row>
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(133.2)">DN125(133.2)</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroTuberiaImpulsion(311.9)">DN300(311.9)</f7-button>
						</f7-col>
					</f7-row>
				</f7-block>

			</div>
		</f7-row>



<f7-row>
		<div class="col">
			<f7-block-title class="margen-izquierdo-standar">{{ $t('SelectedCommercialDiameters') }}</f7-block-title>
			<f7-block strong class="caja caja-coste-energia margen-izquierdo-standar">
				<f7-row>
					<f7-col style="padding-top:20px;">
						<f7-block-title>{{ $t('DischargePipeInternalDiameter') }}</f7-block-title>
						<f7-block-title>{{ $t('SecondaryInternalDiameter') }}</f7-block-title>

					</f7-col>

					<f7-col>
						<f7-list inline-labels no-hairlines-md style="margin-top:20px;">
							<f7-list-input type="number" name="diametro_interno_tuberia-impulsion" :value="diametroTuberiaImpulsion" readonly></f7-list-input>
							<f7-list-input type="number" name="diametro_interno_secundaria" :value="diametroSecundaria" readonly></f7-list-input>

						</f7-list>
					</f7-col>


				</f7-row>
			</f7-block>

		</div class="col">
<div class="col">
				<f7-block-title class="margen-izquierdo-standar">{{ $t('CommercialPVCDiameters') }}</f7-block-title>
				<f7-block strong class="caja caja-diametros-comerciales">
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(46.8)">PVC50(46.8)PN6</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(46.8)">PVC140(133)PN6</f7-button>
						</f7-col>
					</f7-row>
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(59)">PVC63(59)PN6</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(152)">PVC160(152)PN6</f7-button>
						</f7-col>
					</f7-row>
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(70.4)">PVC75(70.4)PN6</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(171.2)">PVC180(171.2)PN6</f7-button>
						</f7-col>
					</f7-row>
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(84.4)">PVC90(84.4)PN6</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(233.5)">DN225(233.5)</f7-button>
						</f7-col>
					</f7-row>
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(190.2)">PVC200(190.2)PN6</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(104.6)">PVC110(104.6)PN6</f7-button>
						</f7-col>
					</f7-row>
					<f7-row style="margin-left:50px">
						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(237.6)">PVC250(237.6)PN6</f7-button>
						</f7-col>

						<f7-col>
							<f7-button fill class="boton-standar" fill @click="setDiametroSecundaria(299.6)">PVC315(299.6)PN6</f7-button>
						</f7-col>
					</f7-row>
				</f7-block>
			</div>







</f7-row>

        <f7-block>
                    <f7-row class="padding-bottom">
                            <f7-col width="20">
                                <f7-button fill href='/costesInversion/'>{{ $t('PreviousText') }}</f7-button>
                            </f7-col>

                            <f7-col width="20">
                                <f7-button href='/resultadosTeoricos/' fill @click="sendDopirModelOptimizer()">
                                  {{ $t('NextText') }}
                                </f7-button>
                            </f7-col>

                    </f7-row>
        </f7-block>


	</f7-page>
</template>

<script>
import Navbardop from '../components/Navbardop.vue'
import Api from '../js/Services/Api.js';
import { mapActions, mapState } from 'vuex'
export default {
	data() {
		return {

		}
	  },
	 components: {
      Navbardop
    },
	computed: {
    ...mapState('caracteristicasBomba', ['caracteristicasBomba', 'diametroTuberiaImpulsion', 'diametroSecundaria']),
  },
	methods: {
		async sendDopirModelOptimizer() {
            this.$f7.preloader.show();
            try {
            const newDopirModelOptimizer = {
                identificadorProyecto : this.caracteristicasBomba.identificadorProyecto,
				diametroTuberiaImpulsion : this.diametroTuberiaImpulsion,
				diametroSecundaria : this.diametroSecundaria
				};
			const respuesta = await Api.requestDopirModelOptimizer(newDopirModelOptimizer, this.caracteristicasBomba.scenario_id);
            this.setDopirModelOptimizer(JSON.parse(respuesta.response))
            } catch (reason) {
            console.log({reason})
            } finally {
            this.$f7.preloader.hide();
            }
        },
		...mapActions('caracteristicasBomba', ['setDopirModelOptimizer', 'setDiametroTuberiaImpulsion', 'setDiametroSecundaria']),
    },
}
</script>



<style>
.boton-curva-volumen {
  border-radius: 2px;
  width: 150px;
  margin-top: 2px;
  font-size: smaller;
}

.boton-standar {
  border-radius: 2px;
  width: 150px;
  margin-top: 2px;
  font-size: smaller;
}


.caja-coste-energia {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
}

.caja-diametros-comerciales {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
}

.selector {
  margin-right: auto;
  margin-left: -60px;
}

.cuadro-imput-curva-volumen {
  width: 80%;
}
</style>
