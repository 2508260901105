import Framework7 from 'framework7';

export default {
  request(url, payload, verb, processData = true, contentType = 'application/x-www-form-urlencoded', headerAuth, enabledCache,
    callback, timeout = 60000) {  
      Framework7.request({
      url,
      method: verb,
      //cache: enabledCache,
      data: payload.data,
      processData,
      contentType,
      timeout,
      complete: callback,
      headers: { 'Authorization': headerAuth }
    });
  },

  async(url, payload, verb, processData, contentType, headerAuth = null, enabledCache = false) {
    const self = this;
    return new Promise((resolve, reject) => {
      self.request(url, payload, verb, processData, contentType, headerAuth, enabledCache,
        (xhr, status) => {
          if (status >= 300 || status === 'timeout') { // TODO: Tratar adecuademente las respuestas (1xx,2xx,3xx,4xx,5xx)
            if (status === 409) {
              resolve(xhr);
            }
            reject(new Error(`${status}: ${xhr.response}`));
          }
          try {
            resolve(xhr);
          } catch (e) {
            reject(e);
          }
        });
    });
  },

  async blobRequest(url, callback) {
    const logoRes = await fetch(url);
    const blob = await logoRes.blob();
    const reader = new FileReader();
    reader.onloadend = () => callback(reader.result);
    reader.readAsDataURL(blob);
  },
};
