// Import Vue
import Vue from 'vue';

import store from '../store/index';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Importamos la librería de traducción
import i18n from './i18n.config';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';

// Import DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import * as overlay from 'devextreme/ui/overlay';

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

overlay.baseZIndex(9999);

// Init App
new Vue({
  el: '#app',
  // Register App Component
  components: {
    app: App
  },
  render: (h) => h(App),
  i18n,
  store,
});
