<template>
  <DxChart id="chart" :data-source="allData" :customize-point="customizePoint">
     <DxTitle
      text="Rendimiento (%)"
    />
    <DxCommonSeriesSettings type="line" argument-field="Q_output" />
    <DxSeries value-field="Q_rend_output" name="Q_rend_output" />

    <DxLegend :visible="false" />

    <DxTooltip
      :enabled="true"
      :zIndex="999999"
      :customize-tooltip="customizeTooltip"
    />
    <DxSize :height="300" :width="600" />
  </DxChart>
</template>
<script>
import {
  DxChart,
  DxSize,
  DxTitle,
  DxCommonSeriesSettings,
  DxSeries,
  DxLegend,
  DxArgumentAxis,
  DxValueAxis,
  DxCommonAnnotationSettings,
  DxFont,
  DxImage,
  DxAnnotation,
  DxCrosshair,
  DxTooltip,
} from "devextreme-vue/chart";

export default {
  components: {
    DxChart,
    DxSize,
    DxTitle,
    DxCommonSeriesSettings,
    DxSeries,
    DxLegend,
    DxArgumentAxis,
    DxValueAxis,
    DxCommonAnnotationSettings,
    DxFont,
    DxImage,
    DxAnnotation,
    DxCrosshair,
    DxTooltip,
  },
  props: {
    responseCurvasCarac: { type: Object, default: () => {} },
  },
  data() {
    return {
      allData: {},
      point: {},
    };
  },
  beforeMount() {
    const formattedData = this.responseCurvasCarac.Q_rend_output.map((element, index) => {
      return {
        Q_rend_output: element,
        Q_output: this.responseCurvasCarac.Q_output[index],
      };
    });

    this.point = {
      Q_output: this.responseCurvasCarac.Q_rend_xfix_output,
      Q_rend_output: this.responseCurvasCarac.Q_rend_yfix_output,
    };

    formattedData.push({
      Q_output: this.responseCurvasCarac.Q_rend_xfix_output,
      Q_rend_output: this.responseCurvasCarac.Q_rend_yfix_output,
    });
    this.allData = formattedData;
  },
  methods: {
    customizeTooltip(annotation) {
      return {
        text: `x = Q ${annotation.argument.toFixed(2)} (l/s)
           y = R ${annotation.value.toFixed(2)} (%)
          `,
      };
    },
    customizePoint({ value }) {
      if (value == this.point.Q_rend_output) {
        return { color: "#BF5E4D" };
      }
    },
  },
};
</script>
<style scoped>
#chart {
  height: 440px;
}
.tooltip {
  width: 300px;
}
</style>