

<template>
  <f7-block class="main-center-block">
    <div class="container">

      <div class="box">
        <f7-link href="https://crea.uclm.es/siar/siarpr" external>
          <f7-card class="demo-card-header-pic">
            <f7-card-content class="img-mopeco" />
            <f7-card-footer>
              <f7-link class="card-footer-text"
                >MOPECO<br />Irrigation Scheduling</f7-link
              >
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>

      <div class="box">
        <f7-link href="/portal/mopeco/" external>
          <f7-card class="demo-card-header-pic">
            <f7-card-content class="img-mopeco" />
            <f7-card-footer>
              <f7-link class="card-footer-text"
                >MOPECO<br />Crops Distribution</f7-link
              >
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>

      <div class="box">
        <f7-link class="link external" href="/media/PRESUD.zip">
          <f7-card class="demo-card-header-pic">
            <f7-card-content  class="img-presud" />
            <f7-card-footer >
              <f7-link class="card-footer-text tooltip-init"
               data-tooltip="<h5>1.Download PRESUD MANUAL,<br>
                    2.Download ans install the MATLAB compiler as indicated in the manual,<br>
                    3.Work with PRESUD program,<br>
                    4.Download the PRESUD presentation</h5>"
                >PRESUD</f7-link
              >
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>

      <div class="box">
        <f7-link href="/parametrosHidraulicos">
          <f7-card class="demo-card-header-pic">
            <f7-card-content class="img-dopirSolar" />
            <f7-card-footer>
              <f7-link class="card-footer-text">DOPIR-SOLAR</f7-link>
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>

      <div class="box">
        <f7-link class="link external" href="/media/DOPIR_Sprinkler_MCR2019b.zip">
          <f7-card class="demo-card-header-pic">
            <f7-card-content class="img-dopir" />
            <f7-card-footer class="tooltip-init"
              data-tooltip="<h5>1.Download DOPIR MANUAL,<br>
                    2.Download ans install the MATLAB compiler as indicated in the manual,<br>
                    3.Work with DOPIR program,<br>
                    4.Download the DOPIR presentation</h5>">
              <f7-link class="card-footer-text">
                DOPIR</f7-link>
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>



      <div class="box">
          <f7-link class="link external" href=" http://irey.ingc.com.tn/">
          <f7-card class="demo-card-header-pic">
            <f7-card-content class="img-irey" />
            <f7-card-footer>
              <f7-link class="card-footer-text">
                IREY<br />Irrigation Scheduling</f7-link>
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>

      <div class="box">
        <f7-link href="https://www.3dsameteo.gr/supromed" external>
          <f7-card class="demo-card-header-pic">
            <f7-card-content class="img-wrf" />
            <f7-card-footer>
              <f7-link class="card-footer-text"
                >WRF<br />Weather Forecast</f7-link
              >
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>

      <div class="box">
        <f7-link href="" external>
          <f7-card class="demo-card-header-pic">

            <f7-card-content class="img-agroclimatic">
               <p class="row">
                 <!--
                <button class="col button button-fill color-blue">TN</button>
                <button class="col button button-fill color-blue">SP</button>
                -->
                <button class="button-information"><abbr :title="information">i</abbr></button>
              </p>
              <div class="text-agroclimatic">Agroclimatic Zone</div>
            </f7-card-content>
            <f7-card-footer>
              <f7-link class="card-footer-text"
                ><button class="col button button-fill color-blue" onclick="window.open('https://dss.supromed.eu/media/thessaly/AGROCLIMATIC_tunisia.zip');">TN</button> </f7-link
              >
              <f7-link class="card-footer-text"
                ><button class="col button button-fill color-blue" onclick="window.open('https://dss.supromed.eu/media/thessaly/AGROCLIMATIC_spain.zip');">SP</button> </f7-link
              >
              <f7-link class="card-footer-text"
                ><button class="col button button-fill color-blue" onclick="window.open('https://dss.supromed.eu/media/thessaly/AGROCLIMATIC_lebanon.zip');">LB</button> </f7-link
              >
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>

      <div class="box">
        <f7-link href="" external>
          <f7-card class="demo-card-header-pic">
            <f7-card-content class="img-ett">
              <p class="row">
                 <!--
                <button class="col button button-fill color-blue">TN</button>
                <button class="col button button-fill color-blue">SP</button>
                -->
                <button class="button-information"><abbr :title="information">i</abbr></button>
              </p>

              <div class="text-ett">ETT</div>
            </f7-card-content>
            <f7-card-footer>
              <f7-link class="card-footer-text"
                ><button class="col button button-fill color-blue" onclick="window.open('https://dss.supromed.eu/media/thessaly/ETT_tunisia.zip');">TN</button> </f7-link
              >
              <f7-link class="card-footer-text"
                ><button class="col button button-fill color-blue" onclick="window.open('https://dss.supromed.eu/media/thessaly/ETT_spain.zip');">SP</button> </f7-link
              >
              <f7-link class="card-footer-text"
                ><button class="col button button-fill color-blue" onclick="window.open('https://dss.supromed.eu/media/thessaly/ETT_lebanon.zip');">LB</button> </f7-link
              >
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>

      <div class="">
        <f7-link href="https://crea.uclm.es/siar/fertilizacionMineral/inicio" external>
          <f7-card class="demo-card-header-pic">
            <f7-card-content class="img-siar"/>

            <f7-card-footer>
              <f7-link class="card-footer-text-siar"
                > Fertilización Mineral. Balance de fertilización (NPK)</f7-link
              >
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>

      <div class="box">
        <f7-link href="/tutorials/">
          <f7-card class="demo-card-header-pic">
            <f7-card-content class="img-mopeco" />
            <f7-card-footer>
              <f7-link class="card-footer-text">
                MOPECO
                <br/>
                Tutorials
              </f7-link>
            </f7-card-footer>
          </f7-card>
        </f7-link>
      </div>
   </div>

    <popUp />
  </f7-block>
</template>

<script>
import popUp from "./popUp.vue";

export default {
  components: {
    popUp,
  },
  data() {
    return {
      popUp,
      information: 'To estimate ETT for other region please contact: faraslis@uth.gr name: Giannis Faraslis'
    };
  },
};
</script>

<style scoped>
.main-center-block .container{
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(175px,1fr));
  grid-template-rows: auto;
  max-width: 1110px;
  margin: 0 auto;
}

.main-center-block .link, .card {
  width: 100%;
}

.main-center-block .card-content{
    height: 110px;
}
.main-center-block .card-footer{
  background-color: #31507C;
}
a.card-footer-text.link{
  color: white;
  font-weight: 500;
  padding-right: 10px;
}
a.card-footer-text-siar.link{
  color: white;
  font-weight: 100;
}
.main-center-block .link,.main-center-block .tab-link{
 justify-content: flex-start;
}
.main-center-block .card-content{
  background-color: #e1e0e2;
}
.button-information {
  margin-left: 140px;
  margin-top: -10px;
  width: 20px;
  border-radius: 20px ;
  background-color: yellow;
}
.text-ett{
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-weight: 500;
    color: white;
    height: 20px;
    width: 192px;
    background: #31507C;
    text-align: center;
}
.text-agroclimatic{
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-weight: 500;
    color: white;
    height: 20px;
    width: 192px;
    background: #31507C;
    text-align: center;
}
/***** Backgrounds *****/
.img-mopeco {
  background:#ececec url(/static/img/imgMopeco.PNG) no-repeat center center;
  background-size: contain;

}

.img-presud {
  background:#ececec url(/static/img/imgPresud.PNG) no-repeat center center;
  background-size: contain;

}

.img-dopir {
  background:#ececec url(/static/img/imgDopir.PNG) no-repeat center center;
  background-size: contain;

}

.img-dopirSolar {
  background:#ececec url(/static/img/dopirSolar.png) no-repeat center center;
  background-size: contain;

}

.img-irey {
  background:#ececec url(/static/img/imgIrey.png) no-repeat center center;
  background-size: cover;
}

.img-wrf {
  background:#ececec url(/static/img/wrf_3dsa_logo.png) no-repeat center center;
  background-size: cover;
}
.img-agroclimatic {
  background:#ececec url(/static/img/uthGreece.png) no-repeat center center;
  background-size: contain;

}
.img-ett {
  background:#ececec url(/static/img/uthGreece.png) no-repeat center center;
  background-size: contain;
}
.img-siar {
  background:#ececec url(/static/img/imgSiar.png) no-repeat center center;
  background-size: cover;
  padding-bottom: 1px;
}
</style>
