export default {
    namespaced: true,
    state: {
      volumenDemandado:[],
      evolucionMensual: [],
      superficieRegada: 0,
      numeroSectores:0,
      presionSubunidad:0,
      separacionRamales:0,
      separacionEmisores:0,
      qNominal:0,
      distanciaParcela:0,
      curvaVolumenObject:{},
      aquiferObject:{},
    },
  
    getters: {
      a: (state) => state.a,
      b: (state) => state.b,
      tr: (state) => state.tr,
      evolucionMensual: (state) => state.evolucionMensual,
      superficieRegada: (state) => state.superficieRegada,
      numeroSectores: (state) => state.numeroSectores,
      presionSubunidad: (state) => state.presionSubunidad,
      separacionRamales: (state) => state.separacionRamales,
      separacionEmisores: (state) => state.separacionEmisores,
      qNominal: (state) => state.qNominal,
      distanciaParcela: (state) => state.distanciaParcela,
      k: (state) => state.k,
      r: (state) => state.r
    },
  
    actions: {
        A(context, value) {
          context.commit('A', value);
        },
        B(context, value) {
          context.commit('B', value);
        },
        Tr(context, value) {
          context.commit('Tr', value);
        },
        setCurvaVolumenObject(context, value) {
          context.commit('setCurvaVolumenObject', value);
        },
        setCellVolumenDemandado(context, value) {
          context.commit('setCellVolumenDemandado', value);
        },
        setVolumenDemandado(context, value) {
          context.commit('setVolumenDemandado', value);
        },

        setCellEvolucionMensual(context, value) {
          context.commit('setCellEvolucionMensual', value);
        },

        setEvolucionMensual(context, value) {
          context.commit('setEvolucionMensual', value);
        },

        setSuperficieRegada(context, value) {
          context.commit('setSuperficieRegada', value);
        },

        setNumeroSectores(context, value) {
          context.commit('setNumeroSectores', value);
        },

        setPresionSubunidad(context, value) {
          context.commit('setPresionSubunidad', value);
        },

        setSeparacionRamales(context, value) {
          context.commit('setSeparacionRamales', value);
        },

        setSeparacionEmisores(context, value) {
          context.commit('setSeparacionEmisores', value);
        },

        setQNominal(context, value) {
          context.commit('setQNominal', value);
        },

        setDistanciaParcela(context, value) {
          context.commit('setDistanciaParcela', value);
        },

        K(context, value) {
          context.commit('K', value);
        },

        R(context, value) {
          context.commit('R', value);
        },
        setAquiferObject(context, value) {
          context.commit('setAquiferObject', value);
        },

    },
  
    mutations: {
      A(state, value) {
            state.curvaVolumenObject.a = value;
      },

      B(state, value) {
        state.curvaVolumenObject.b = value;
      },

      Tr(state, value) {
        state.curvaVolumenObject.tr = value;
      },

      K(state, value) {
        state.aquiferObject.k = value;
      },

      R(state, value) {
        state.aquiferObject.r = value;
      },

      setSuperficieRegada(state, value) {
        state.superficieRegada = value;
      },

      setNumeroSectores(state, value) {
        state.numeroSectores = value;
      },

      setPresionSubunidad(state, value) {
        state.presionSubunidad = value;
      },

      setSeparacionRamales(state, value) {
        state.separacionRamales = value;
      },

      setSeparacionEmisores(state, value) {
        state.separacionEmisores = value;
      },

      setQNominal(state, value) {
        state.qNominal = value;
      },

      setDistanciaParcela(state, value) {
        state.distanciaParcela = value;
      },
      
      setCellEvolucionMensual(state, value) { 
        state.evolucionMensual[value.pos] = value.data;
       

      },

      setEvolucionMensual(state, value) {
        state.evolucionMensual = value;
      },

            
      setCellVolumenDemandado(state, value) { 
        state.volumenDemandado[value.pos] = value.data;
       

      },

      setVolumenDemandado(state, value) {
        state.volumenDemandado = value;
      },

      setCurvaVolumenObject(state, value) {
        state.curvaVolumenObject = value;
      },
      setAquiferObject(state, value) {
        state.aquiferObject = value;
      },
    },
  };
  