import {
  COUNTRY,
} from '../../js/constants';
import EventBus from '../../js/event-bus';

export default {
  namespaced: true,

  state: {
    languageSelected: COUNTRY,
  },

  getters: {
  },

  actions: {
    setLanguageSelected({ commit }, payload) {
      commit('setLanguageSelected', payload);
    },
  },

  mutations: {
    setLanguageSelected(state, payload) {
      state.languageSelected = payload;
    },
  },
};
