<template>
  <f7-navbar class="custom-navbar" >
    <f7-nav-title v-if="title" class="title-head">
      {{ title }}
    </f7-nav-title>
    <f7-nav-left v-if="showLogo" class="header-space">
      <f7-link href="/" >
        <img
            src="/static/img/logo_Supromed 1.svg"
            class="img-surpomed"
        >
      </f7-link>
    </f7-nav-left>
    <f7-nav-right class="nav-right">
      <selectLanguage
          :languages="languages"
          :language-selected="languageSelected"
          @changeLanguage="changeLanguage"
      />
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import SelectLanguage from './SelectLanguage.vue';
import { LANGUAGES } from '../js/constants';
import EventBus from '../js/event-bus';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    showLogo: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    SelectLanguage,
  },
  data() {
    return {
      languages: LANGUAGES,
    };
  },
  computed: {
    ...mapState('authentication', ['languageSelected']),
  },
  methods: {
    changeLanguage(lan) {
      this.setLanguageSelected(lan);
      EventBus.$emit('change-language', lan);
    },
    ...mapActions('authentication', ['setLanguageSelected']),
  },
};
</script>
<style lang="scss" scoped>
.nav-right {
  padding: 1rem;
}
</style>
