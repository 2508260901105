import {
    f7,
  } from 'framework7-vue';
  import Request from './Request';

  
  //const baseUrl = 'http://127.0.0.1:8080';
  //PRODUCCIÓN
  const baseUrl = 'https://supromed.hispatecanalytics.com';
  const token = 'Bearer 5d1abf9ae5bb4f45f89f96d8130fdcb4fe0d2668'
  //DESARROLLO
  // const baseUrl = 'https://supromed.development.hispatecanalytics.com';
  // const token = 'Bearer 2395fc4c2269f49c3c96973d896e0375ec281666';
  
  export default {

    listadoGet() {
        const url = `${baseUrl}/dopir/curvascarac/`;
        const payload = {
            data: null,
        };

        return Request.async(url, payload, 'POST', false, 'application/json', token);
    },

    parametrosHidraulicos() {
      const url = `${baseUrl}/dopir/`;
        const payload = {
            data:{            
            volumen_demandado_1: this.volumenDemandado[0],
            volumen_demandado_2: this.volumenDemandado[1],
            volumen_demandado_3: this.volumenDemandado[2],
            volumen_demandado_4: this.volumenDemandado[3],
            volumen_demandado_5: this.volumenDemandado[4],
            volumen_demandado_6: this.volumenDemandado[5],
            volumen_demandado_7: this.volumenDemandado[6],
            volumen_demandado_8: this.volumenDemandado[7],
            volumen_demandado_9: this.volumenDemandado[8],
            volumen_demandado_10: this.volumenDemandado[9],
            volumen_demandado_11: this.volumenDemandado[10],
            volumen_demandado_12: this.volumenDemandado[11],

            evolucion_mensual_1: this.evolucionMensual[0],
            evolucion_mensual_2: this.evolucionMensual[1],
            evolucion_mensual_3: this.evolucionMensual[2],
            evolucion_mensual_4: this.evolucionMensual[3],
            evolucion_mensual_5: this.evolucionMensual[4],
            evolucion_mensual_6: this.evolucionMensual[5],
            evolucion_mensual_7: this.evolucionMensual[6],
            evolucion_mensual_8: this.evolucionMensual[7],
            evolucion_mensual_9: this.evolucionMensual[8],
            evolucion_mensual_10: this.evolucionMensual[9],
            evolucion_mensual_11: this.evolucionMensual[10],
            evolucion_mensual_12: this.evolucionMensual[11],

            datos_aquiferos_k: this.datosAquiferos.k,
            datos_aquiferos_r: this.datosAquiferos.r,

            superficie_regada: this.superficieRegada,
            numero_sectores: this.numeroSectores,
            presion_subunidad: this.presionSubunidad,
            separacion_ramales: this.separacionRamales,
            separacion_emisores: this.separacionEmisores,
            q_nominal: this.qNominal,
            distancia_parcela: distanciaParcela,

            volumen_curva_a : this.curvaVolumenObject.a,
            volumen_curva_b : this.curvaVolumenObject.b,
            volumen_curva_tr : this.curvaVolumenObject.tr
          }

        };

        return Request.async(url, payload, 'POST', false, 'application/json', token);
    },

    getWoodyValues(){
      const url = `${baseUrl}/dopir/defaultvalues/woody volume curve`;
        const payload = {
            data: null,
        };

        return Request.async(url, payload, 'GET', false, 'application/json', token);
    },

    getVegetableValues(){
      const url = `${baseUrl}/dopir/defaultvalues/vegetable volume curve`;
        const payload = {
            data: null,
        };

        return Request.async(url, payload, 'GET', false, 'application/json', token);
    },

    getAquifer1Values(){
      const url = `${baseUrl}/dopir/defaultvalues/1 aquifer`;
        const payload = {
            data: null,
        };

        return Request.async(url, payload, 'GET', false, 'application/json', token);
    },

    getAquifer2Values(){
      const url = `${baseUrl}/dopir/defaultvalues/2 aquifer`;
        const payload = {
            data: null,
        };

        return Request.async(url, payload, 'GET', false, 'application/json', token);
    },

    getAquifer2Values(){
      const url = `${baseUrl}/dopir/defaultvalues/2 aquifer`;
        const payload = {
            data: null,
        };

        return Request.async(url, payload, 'GET', false, 'application/json', token);
    },

    getVegetableSubunitCostValues(){
      const url = `${baseUrl}/dopir/defaultvalues/vegetable subunit cost`;
        const payload = {
            data: null,
        };

        return Request.async(url, payload, 'GET', false, 'application/json', token);
    },

    getVineyardSubunitCostValues(){
      const url = `${baseUrl}/dopir/defaultvalues/vineyard subunit cost`;
        const payload = {
            data: null,
        };

        return Request.async(url, payload, 'GET', false, 'application/json', token);
    },

    getDefaultCostValues(){
      const url = `${baseUrl}/dopir/defaultvalues/default cost`;
        const payload = {
            data: null,
        };

        return Request.async(url, payload, 'GET', false, 'application/json', token);
    },

    getDefaultDieselCostValues(){
      const url = `${baseUrl}/dopir/defaultvalues/default diesel generator cost`;
        const payload = {
            data: null,
        };

        return Request.async(url, payload, 'GET', false, 'application/json', token);
    },



    curvasCarac(newCaracteristicas) {
      const url = `${baseUrl}/dopir/curvascarac/`;
      const payload = {
        data: JSON.stringify(newCaracteristicas),
      };
  
      return Request.async(url, payload, 'POST', false, 'application/json', token);
    },

    requestDopirModel(newDopirModel){
      const url = `${baseUrl}/dopir/requestDopirModel/`;
        const payload = {
            data: JSON.stringify(newDopirModel),
        };

        return Request.async(url, payload, 'POST', false, 'application/json', token);
    },
    requestDopirModelOptimizer(newDopirModelOptimizer, scenario_id){
      const url = `${baseUrl}/dopir/requestDopirModelOptimizer/${scenario_id}`;
        const payload = {
            data: JSON.stringify(newDopirModelOptimizer),
        };

        return Request.async(url, payload, 'POST', false, 'application/json', token);
    },
  
    //  Auxiliary methods
    async request(url, payload, verb, processData, contentType, headerAuth) {
      const response = await Request.async(url, payload, verb, processData, contentType, headerAuth);
      return response;
    },
  
    getException(errorcode) {
      return errorcode in this.errors ? this.errors[errorcode] : this.errors.default;
    },

  };
  