<
<template>
   <f7-page name="resultadosTeoricos" class="fondo-pantalla-completa">
      <Navbardop
      slot="fixed"
      nav-title="Detalle Trabajador"
      />
      <div  <div class="texto-grande padding-left">{{$t('ResultsText')}}</div>

      <f7-row>
         <f7-block style ="margin-left: 15%">
            <div class="col">
            <f7-block-title class="margen-izquierdo-standar">{{$T('CharacteristicCurves')}}</f7-block-title>
               <f7-row>
                  <f7-col>
                     <f7-button fill class="boton-standar" href='/graphicPage/'>{{ $t('FiguresText') }}</f7-button>
                  </f7-col>
               </f7-row>
            </div class="col">
            <!-- <div class="col">
            <f7-block-title class="margen-izquierdo-standar">Horas Usadas</f7-block-title>
            <f7-block class="caja caja-coste-energia">
               <f7-row>
                  <f7-col>
                     <f7-button fill class="boton-standar" fill>Figuras</f7-button>
                  </f7-col>
               </f7-row>
            </f7-block>
            </div class="col"> -->
         </f7-block>
         <div style ="margin-right:140px">
            <f7-block-title>{{$T('MainCharacteristicsPump')}}</f7-block-title>
            <div class="caja caja-diametros-comerciales padding">
               <f7-row>
                  <f7-col>
                     <f7-block-title>{{ $t('FlowText') }} </f7-block-title>
                     <f7-block-title>{{$t('PowerText')}}</f7-block-title>
                     <f7-block-title>{{ $t('PipeDiameter') }}</f7-block-title>
                     <f7-block-title>{{ $t('SecondaryDiameter') }}</f7-block-title>
                  </f7-col>
                  <f7-col>
                     <f7-list inline-labels no-hairlines-md style="margin-top:-5px;  width:90px;">
                        <f7-list-input type="number" name="caudal" :value="dopirModelOptimizer.caudal" readonly></f7-list-input>
                        <f7-list-input type="number" name="potencia" :value="dopirModelOptimizer.potencia" readonly></f7-list-input>
                        <f7-list-input type="number" name="diametro_tuberia" :value="dopirModelOptimizer.diametroTuberiaBombeo" readonly></f7-list-input>
                        <f7-list-input type="number" name="diametro_secundaria" :value="dopirModelOptimizer.diametroTuberiaSecundaria" readonly></f7-list-input>
                     </f7-list>
                  </f7-col>

               </f7-row>
               <f7-row>
                     <f7-col>
                        <f7-block-title>H=</f7-block-title>
                        <f7-block-title>ƞ=</f7-block-title>

                     </f7-col>
                     <f7-col>
                        <f7-list inline-labels no-hairlines-md style="margin-top:-5px;  width:90px;">
                           <f7-list-input type="number" name="h" :value="dopirModelOptimizer.coeficienteA" readonly></f7-list-input>
                           <f7-list-input type="number" name="ƞ" :value="dopirModelOptimizer.coeficienteD" readonly></f7-list-input>
                        </f7-list>
                     </f7-col>
                     <f7-col >
                        <f7-block-title>Q^2</f7-block-title>
                        <f7-block-title>Q^2</f7-block-title>
                     </f7-col>
                     <f7-col>
                        <f7-list inline-labels no-hairlines-md style="margin-top:-5px;  width:90px;">
                           <f7-list-input type="number" name="q_2" :value="dopirModelOptimizer.coeficienteC" readonly></f7-list-input>
                           <f7-list-input type="number" name="Q_2" :value="dopirModelOptimizer.coeficienteE" readonly></f7-list-input>
                        </f7-list>
                     </f7-col>
                  </f7-row>
            </div>
         </div>
         <!--<div class="col">
            <f7-block-title class="margen-izquierdo-standar">Evolución del nivel dinámico (m)</f7-block-title>
            <f7-block class="caja caja-meses">
                <f7-row>
                  <f7-col>
                     <f7-block-title>Enero</f7-block-title>
                     <f7-block-title>Febrero</f7-block-title>
                     <f7-block-title>Marzo</f7-block-title>
                     <f7-block-title>Abril</f7-block-title>
                     <f7-block-title>Mayo</f7-block-title>
                     <f7-block-title>Junio</f7-block-title>
                  </f7-col>
                  <f7-col>
                     <f7-list inline-labels no-hairlines-md style="margin-top:-5px;  width:90px;">
                        <f7-list-input type="number" name="enero" readonly></f7-list-input>
                        <f7-list-input type="number" name="febrero" readonly></f7-list-input>
                        <f7-list-input type="number" name="marzo" readonly></f7-list-input>
                        <f7-list-input type="number" name="abril" readonly></f7-list-input>
                        <f7-list-input type="number" name="mayo" readonly></f7-list-input>
                        <f7-list-input type="number" name="junio" readonly></f7-list-input>
                     </f7-list>
                  </f7-col>
                  <f7-col >
                     <f7-block-title>Julio</f7-block-title>
                     <f7-block-title>Agosto</f7-block-title>
                     <f7-block-title>Septiembre</f7-block-title>
                     <f7-block-title>Octubre</f7-block-title>
                     <f7-block-title>Noviembre</f7-block-title>
                     <f7-block-title>Diciembre</f7-block-title>
                  </f7-col>
                  <f7-col>
                     <f7-list inline-labels no-hairlines-md style="margin-top:-5px;  width:90px;">
                        <f7-list-input type="number" name="julio" readonly></f7-list-input>
                        <f7-list-input type="number" name="agosto" readonly></f7-list-input>
                        <f7-list-input type="number" name="septiembre" readonly></f7-list-input>
                        <f7-list-input type="number" name="octubre" readonly></f7-list-input>
                        <f7-list-input type="number" name="noviembre" readonly></f7-list-input>
                        <f7-list-input type="number" name="diciembre" readonly></f7-list-input>
                     </f7-list>
                  </f7-col>
               </f7-row>
            </f7-block>
         </div>-->
         <!-- <div class="col">
            <f7-block-title style ="margin-left:275px" >Sondeo</f7-block-title>
            <f7-block class="caja caja-sondeo">
               <f7-row style="width:350px;">
                  <f7-col>
                     <f7-block-title>Profundida de la bomba (m) </f7-block-title>
                     <f7-block-title>Diametro del sondeo (mm)</f7-block-title>
                     <f7-block-title>Profundidad del sondeo (m)</f7-block-title>
                  </f7-col>
                  <f7-col>
                     <f7-list inline-labels no-hairlines-md style="margin-top:-5px;  width:90px;">
                        <f7-list-input type="number" name="profundidad_bomba" readonly></f7-list-input>
                        <f7-list-input type="number" name="diametro_sondeo" readonly></f7-list-input>
                        <f7-list-input type="number" name="profundidad_sondeo" readonly></f7-list-input>
                     </f7-list>
                  </f7-col>
               </f7-row>
            </f7-block>
         </div> -->
      </f7-row>
      <f7-row>
         <div class="col">
         <f7-block-title class="text-align-center" >{{$t('CostText')}}</f7-block-title>


         <f7-block class="caja caja-coste">
<f7-block >
               <f7-row>
                  <f7-col style="margin-top:6%">
                     <f7-block-title>{{ $t('InvestmentText') }}</f7-block-title>
                     <f7-block-title>{{ $t('EnergyIText')}}</f7-block-title>
                     <f7-block-title>{{ $t('TotalYear') }}</f7-block-title>

                  </f7-col>
                  <f7-col style="margin-top:5%">
                     <f7-list inline-labels no-hairlines-md style="width:90px;">
                        <f7-list-input type="number" name="inversion_euro_año" :value="dopirModelOptimizer.costeInversionAnio" readonly></f7-list-input>
                        <f7-list-input type="number" name="energia_euro_año" :value="dopirModelOptimizer.costeEnergiaAnio" readonly></f7-list-input>
                        <f7-list-input type="number"name ="total_euro_año" :value="dopirModelOptimizer.costeTotalAnio" readonly></f7-list-input>
                     </f7-list>

                  </f7-col>
                  <f7-col style="margin-top:6%">
                     <f7-block-title>{{ $t('InvestmentHA') }}</f7-block-title>
                     <f7-block-title>{{ $t('EnergyHA') }}</f7-block-title>
                     <f7-block-title>{{ $t('TotalHA') }}</f7-block-title>
                  </f7-col>
                  <f7-col style="margin-top:5%">
                     <f7-list inline-labels no-hairlines-md style="width:90px;">
                        <f7-list-input type="number" name="inversion_euro_ha_año" :value="dopirModelOptimizer.costeTotalAnio" readonly></f7-list-input>
                        <f7-list-input type="number" name="energia_euro_ha_año" :value="dopirModelOptimizer.costeEnergiaSuperficie" readonly></f7-list-input>
                        <f7-list-input type="number" name="total_euro_ha_año" :value="dopirModelOptimizer.costeTotalSuperficie" readonly></f7-list-input>
                     </f7-list>
                  </f7-col >
				      <!-- <f7-col >
                     <f7-block-title>Linea electrica  €/ha y año</f7-block-title>
                     <f7-block-title>Sondeo €/ha y año</f7-block-title>
                     <f7-block-title>Tuberia secundaria  €/ha y año</f7-block-title>
                     <f7-block-title>Tuberia impulsión  €/ha y año</f7-block-title>
                     <f7-block-title>Transform/grupo €/ha y año</f7-block-title>
                     <f7-block-title>Bomba  €/ha y año</f7-block-title>
                     <f7-block-title>Instalacion electrica  €/ha y año</f7-block-title>
						 <f7-block-title>Valvulas hidraulicas €/ha y año</f7-block-title>
						 	 <f7-block-title>Subunidad €/ha y año</f7-block-title>
                  </f7-col>
                  <f7-col >
                     <f7-list inline-labels no-hairlines-md style="margin-top:-5px;  width:90px;">
                        <f7-list-input type="number" name="linea_electrica_euro_ha_año" readonly></f7-list-input>
                        <f7-list-input type="number" name="sondeo_euro_ha_año" readonly></f7-list-input>
                        <f7-list-input type="number" name="tuberia_secundaria_euro_ha_año" readonly></f7-list-input>
						      <f7-list-input type="number" name="tuberia_impulsion_euro_ha_año" readonly></f7-list-input>
                        <f7-list-input type="number" name="transformGrupo_euro_ha_año" readonly></f7-list-input>
                        <f7-list-input type="number" name="bomba_euro_ha_año" readonly></f7-list-input>
						      <f7-list-input type="number" name="instalacion_electrica_euro_ha_año" readonly></f7-list-input>
                        <f7-list-input type="number" name="valvulas_hidraulicas_euro_ha_año" readonly></f7-list-input>
                        <f7-list-input type="number" name="subunidad_euro_ha_año" readonly></f7-list-input>
                     </f7-list>
                  </f7-col> -->
               </f7-row>
            </f7-block>


         </f7-block>
         </div class="col">
      </f7-row>
      <f7-block>
         <f7-row class="padding">
               <f7-col width="30">
                     <f7-button fill  class="boton-standar" href='/caracteristicasBomba/'>
                       {{ $t('PreviousText') }}
                     </f7-button>
               </f7-col>
               <f7-col width="30">
                     <f7-button fill class="boton-standar" fill>{{$t('FinishText')}}</f7-button>
               </f7-col>
         </f7-row>
      </f7-block>
   </f7-page>
</template>

<script>
import Navbardop from '../components/Navbardop.vue'
import { mapActions, mapState } from 'vuex'
export default {

    components: {
      Navbardop
    },
    computed:{
       ...mapState('caracteristicasBomba', ['dopirModelOptimizer']),
    },
    method:{
       ...mapActions('caracteristicasBomba', ['setDopirModelOptimizer']),
    }

}
</script>


<style>
.boton-curva-volumen {
  border-radius: 2px;
  width: 150px;
  margin-top: 2px;
  font-size: smaller;
}
.boton-standar {
  border-radius: 2px;
  width: 150px;
  margin-top: 2px;
  font-size: smaller;
  margin-left: auto;
  margin-right: auto;
}
.caja-coste-energia {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
}
.caja-coste {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
  width: 85%;
  margin-right: auto;
  margin-left: auto;
}
.caja-diametros-comerciales {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
  width: 450px;
}
.caja-sondeo {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
  width: 450px;
  margin-right: auto;
  margin-left: auto;
}
.caja-meses {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
  width: 700px;
}
.selector {
  margin-right: auto;
  margin-left: -60px;
}
.cuadro-imput-curva-volumen {
  width: 80%;
}
</style>

