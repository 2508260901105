<template>
  <f7-page no-margin
    name="home"
  >
    <Navbardop
        show-logo
    />
    <f7-block
      class="titular-text"
    >
      <strong>{{ $t('Tutorials') }}</strong>
    </f7-block>
    <TutorialsList/>
  </f7-page>
</template>

<script>
import navBar from '../components/navBar.vue';
import Navbardop from "../components/Navbardop.vue";
import TutorialsList from "../components/TutorialsList.vue";

export default {
  name: 'TutorialsPage',
  components: {
    navBar,
    Navbardop,
    TutorialsList
  },
  data() {
    return {
      navBar,
    };
  },
};
</script>

<style lang="scss" scoped>
.titular-text{
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 1;
text-align: center;
color: #42526E;
}

.navbar {
  height: 100px !important;
}
</style>

