<template>
    <f7-page name="home" class="fondo-pantalla-completa">
      <Navbardop
      slot="fixed"
      nav-title="Detalle Trabajador"
      />
      <div class="texto-grande padding-left">
          MOPECO - Suelo
      </div>
    
        <f7-list>
            <f7-list-item title="Suelo" smart-select>
                <select name="Tipo de Suelo">
                    <option value="arrollo_arenoso" selected>Arrollo Arenoso</option>
                    <option value="arrollo_limoso">Arrollo Limoso</option>
                    <option value="arrolloso">Arrolloso</option>
                    <option value="arenoso">Arenoso</option>
                    <option value="arenoso_franco">Arenoso Franco</option>
                    <option value="franco" selected>Franco</option>
                    <option value="franco_arrollo_arenoso">Franco Arrollo Arenoso</option>
                    <option value="franco_arrollo_limoso">Franco Arrollo Limoso</option>
                    <option value="franco_arcilloso">Franco Arcilloso</option>
                    <option value="franco_arenoso">Franco Arenoso</option>
                    <option value="franco_limoso">Franco Limoso</option>
                </select>
            </f7-list-item>
        </f7-list>

        <form class="block">
            <f7-block class="caja caja-volumen-demandado">
                <f7-row class="text-align-center">
                    <f7-col>
                    <f7-block-title>Profundidad Util</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input type="number" name="profundidad_util"> </f7-list-input>
                    </f7-list>
                    </f7-col> 
                </f7-row>
            </f7-block>
        </form>

        <form class="block">
            <f7-block class="caja caja-volumen-demandado">
                <f7-row class="text-align-center">
                    <f7-col>
                    <f7-block-title>Porcentaje de Pedregosidad</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input type="number" name="porcentaje_de_pedregosidad"> </f7-list-input>
                    </f7-list>
                    </f7-col> 
                </f7-row>
            </f7-block>


            <div class="padding-bottom">
                <f7-row>
                    <f7-col width="60">
                    </f7-col>
                    <f7-col width="20">
                    <f7-button fill>Guardar</f7-button>
                    </f7-col>
                </f7-row>
            </div>
        </form>

    </f7-page>
</template>

<script>
export default {
    
}
</script>

<style>
/* .caja-volumen-demandado {
border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
  width: 50%;
  margin-top: -2%;
} */
</style>