// Country
export const COUNTRY = 'es';

export const LANGUAGES = [
  {
    name: 'Languages.English',
    id: 'en',
    icon: '🇺🇸',
  },
  {
    name: 'Languages.Spanish',
    id: 'es',
    icon: '🇪🇸',
  },
  {
    name: 'Languages.Arabian',
    id: 'ar',
    icon: '🇸🇦',
  },
  {
    name: 'Languages.Greek',
    id: 'gr',
    icon: '🇬🇷',
  },
];
