<template>
  <f7-app :params="f7params">
    <f7-view
      :pushState="true"
      :pushStateRoot="/portal/"
      main
      class="safe-areas"
      url="/"
    />
    <div id="app">
    <textarea rows="10" v-model="text"></textarea>
    <br>
		<text-reader @load="text = $event"></text-reader>
	</div>
  </f7-app>
</template>

<script>
import routes from '../js/routes.js';
import esMessages from 'devextreme/localization/messages/es.json';
import grMessages from '../json/translations/devExtremeMessages/gr.json';
import arMessages from '../json/translations/devExtremeMessages/ar.json';
import { locale, loadMessages } from 'devextreme/localization';
import EventBus from '../js/event-bus';

export default {
  data() {
    return {
      f7params: {
        name: 'SuproMed',
        theme: 'auto',
        routes,
      },
    };
  },
  components: {
    FileReader
  },
  created() {
    loadMessages(esMessages);
    loadMessages(arMessages);
    loadMessages(grMessages);
  },
  mounted() {
    this.$f7ready((f7) => {
      this.updateLanguage('en');
      EventBus.$on('change-language', this.updateLanguage);
    });
  },
  methods: {
    updateLanguage(newLanguage) {
      this.$i18n.locale = newLanguage;
      locale(newLanguage);
    },
  }
};
</script>
