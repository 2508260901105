<template>
  <div class="content-cards-tutorials">
    <f7-card class="card-tutorials">
      <f7-card-content class="f7-card-content-tutorials" :padding="false">
        <f7-card-header text-color="white" class="card-bg-color">
          {{ $t('IREYModel') }}
        </f7-card-header>
        <div class="card-content-padding">
          <f7-link class="card-footer-text">
            <button class="col button button-fill color-blue" onclick="window.open('https://docs.google.com/document/d/1v4QixVOWS0BIl5_yW1ifTDNnJp8gyAEZ/edit?usp=sharing&ouid=118160472148818955627&rtpof=true&sd=true');">
              Scipt IREY English - Reviewed.docx
            </button>
          </f7-link>
          <br/>
          <f7-link class="card-footer-text">
            <button class="col button button-fill color-blue" onclick="window.open('https://drive.google.com/file/d/1-iwjkhoxV8Z6ToDsVXJsEG-Heun90dtQ/view');">
              Video IREY_v3.mp4
            </button>
          </f7-link>
        </div>
      </f7-card-content>
    </f7-card>
    <f7-card class="card-tutorials">
      <f7-card-content class="f7-card-content-tutorials" :padding="false">
          <f7-card-header text-color="white" class="card-bg-color">
            {{ $t('WRFARWModel') }}
          </f7-card-header>
        <div class="card-content-padding">
          <f7-link class="card-footer-text">
            <button class="col button button-fill color-blue" onclick="window.open('https://www.3dsameteo.gr/data/supromed/docs/Tutorial_WRF-ARW_20220628.pdf');">
              Tutorial_WRF-ARW_20220628.pdf
            </button>
          </f7-link>
          <br/>
          <f7-link class="card-footer-text">
            <button class="col button button-fill color-blue" onclick="window.open('https://www.3dsameteo.gr/data/supromed/docs/Tutorial_Meteo_Service_20220628.pdf');">
              Tutorial_Meteo_Service_20220628.pdf
            </button>
          </f7-link>
          <p>
            In the following link you can find the necessary file for the translation of the meteo tool to other languages:
          </p>
          <f7-link class="card-footer-text">
            <button class="col button button-fill color-blue" onclick="window.open('https://www.3dsameteo.gr/data/supromed/docs/SUPROMED_Meteo_Service_Translation_3DSA_20220626.xlsx');">
              SUPROMED_Meteo_Service_Translation_3DSA_20220626.xlsx
            </button>
          </f7-link>
        </div>
      </f7-card-content>
    </f7-card>
    <f7-card class="card-tutorials">
      <f7-card-content class="f7-card-content-tutorials" :padding="false">
          <f7-card-header text-color="white" class="card-bg-color">
            {{ $t('IrrigationSchedulingTutorials') }}
          </f7-card-header>
        <div class="card-content-padding">
          <f7-link class="card-footer-text">
            <button class="col button button-fill color-blue" onclick="window.open('../static/resources/MOPECO_IRRIGATION_SCHEDULING_Annual_Crops.pdf');">
              MOPECO_IRRIGATION_SCHEDULING_Annual_Crops.pdf
            </button>
          </f7-link>
          <f7-link class="card-footer-text">
            <button class="col button button-fill color-blue" onclick="window.open('../static/resources/MOPECO_IRRIGATION_SCHEDULING_Tree_Crops.pdf');">
              MOPECO_IRRIGATION_SCHEDULING_Tree_Crops.pdf
            </button>
          </f7-link>
          <br/>
        </div>
      </f7-card-content>
    </f7-card>
    <f7-card class="card-tutorials">
      <f7-card-content class="f7-card-content-tutorials" :padding="false">
          <f7-card-header text-color="white" class="card-bg-color">
            {{ $t('CropDistributionTutorials') }}
          </f7-card-header>
        <div class="card-content-padding">
          <f7-link class="card-footer-text">
            <button class="col button button-fill color-blue" onclick="window.open('../static/resources/MOPECO_CROP_DISTRIBUTION_spanish.pdf');">
              MOPECO_CROP_DISTRIBUTION_spanish.pdf
            </button>
          </f7-link>
        </div>
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
export default {
  name: 'TutorialsList',
  components: {},
  props: {},
  methods: {},
};
</script>

<style>
.card-bg-color {
  background-color: #31507C;
}
.f7-icons {
  color: white !important;
}
.navbar {
  z-index: 200 !important;
}
.content-cards-tutorials {
  padding: 0 1rem;
  display: grid;
  grid-template: repeat(1, 1fr) / repeat(1, 1fr);
  gap: 1rem 1rem;
  justify-items: center;
  align-items: center;
}

@media (min-width: 992px) {
  .content-cards-tutorials {
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  }
}
@media (min-width: 1360px) {
  .content-cards-tutorials {
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  }
}
.card-tutorials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
}
.f7-card-content-tutorials {
  width: auto !important;
  min-width: 100% !important;
  min-height: 280px;
}

</style>
