import HomePage from '../pages/home.vue';
import parametrosHidraulicos from '../pages/parametrosHidraulicos.vue';
import horasMensuales from '../pages/horasMensuales.vue';
import costesInversion from '../pages/costesInversion.vue';
import caracteristicasBomba from '../pages/caracteristicasBomba.vue';
import resultadosTeoricos from '../pages/resultadosTeoricos.vue';
import graphicPage from '../pages/graphicPage.vue';
import parcelas from '../mopeco_pages/parcelas.vue';
import suelo from '../mopeco_pages/suelo.vue';
import TutorialsPage from '../pages/tutorials.vue';
var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/parametrosHidraulicos',
    component: parametrosHidraulicos,
  },
  {
    path: '/horasMensuales/',
    component: horasMensuales,
  },
  {
    path: '/costesInversion/',
    component: costesInversion,
  },
  {
    path: '/caracteristicasBomba/',
    component: caracteristicasBomba,
  },
  {
    path: '/resultadosTeoricos/',
    component: resultadosTeoricos,
  },
  {
    path: '/graphicPage/',
    component: graphicPage,
  },
  {
    path: '/parcelas/',
    component: parcelas
  },
  {
    path: '/suelo/',
    component: suelo
  },
  {
    path: '/tutorials/',
    component: TutorialsPage,
  }
];

export default routes;
