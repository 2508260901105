import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '../json/translations/en-US.json';
import es from '../json/translations/es-ES.json';
import ar from '../json/translations/ar-AR.json';
import gr from '../json/translations/gr-GR.json';

Vue.use(VueI18n);

const dictionaries = {
  en,
  es,
  ar,
  gr,
};
const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  whitelist: ['en', 'es', 'ar', 'gr'],
  messages: dictionaries,
});

export default i18n;
