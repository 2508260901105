<template>
    <f7-page name="horasMensuales"  class="fondo-pantalla-completa">
      <Navbardop
          :title="$t('HoursAvailable')"
      />

        <f7-block class="texto-sobre-caja" style="margin-top: 1rem;">{{ $t('ValleyHours') }}</f7-block>
        <f7-block class="caja caja-volumen-demandado">
        <f7-row class="text-align-center">


                        <f7-col>
                          <f7-block-title>1</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_1" v-model:value="horas[0][0]" @input = "setCellHoras({pos1: 0, pos2: 0, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col>
                          <f7-block-title>2</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_2" v-model:value="horas[0][1]" @input = "setCellHoras({pos1: 0, pos2: 1, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col>
                          <f7-block-title>3</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_3" v-model:value="horas[0][2]" @input = "setCellHoras({pos1: 0, pos2: 2, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col>
                          <f7-block-title>4</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_4" v-model:value="horas[0][3]" @input = "setCellHoras({pos1: 0, pos2: 3, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col>
                          <f7-block-title>5</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_5" v-model:value="horas[0][4]" @input = "setCellHoras({pos1: 0, pos2: 4, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col>
                          <f7-block-title>6</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_6" v-model:value="horas[0][5]" @input = "setCellHoras({pos1: 0, pos2: 5, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col>
                          <f7-block-title>7</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_7" v-model:value="horas[0][6]" @input = "setCellHoras({pos1: 0, pos2: 6, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col>
                          <f7-block-title>8</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_8" v-model:value="horas[0][7]" @input = "setCellHoras({pos1: 0, pos2: 7, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col >
                          <f7-block-title>9</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_9" v-model:value="horas[0][8]" @input = "setCellHoras({pos1: 0, pos2: 8, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col>
                          <f7-block-title>10</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_10" v-model:value="horas[0][9]" @input = "setCellHoras({pos1: 0, pos2: 9, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col>
                          <f7-block-title>11</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_11" v-model:value="horas[0][10]" @input = "setCellHoras({pos1: 0, pos2: 10, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

                        <f7-col>
                          <f7-block-title>12</f7-block-title>
                          <f7-list inline-labels no-hairlines-md>
                            <f7-list-input type="number" name="horas_valle_12" v-model:value="horas[0][11]" @input = "setCellHoras({pos1: 0, pos2: 11, data:$event.target.value})"> </f7-list-input>
                          </f7-list>
                        </f7-col>

          </f7-row>
          <f7-row>
            <f7-col width="85">
            </f7-col>
            <f7-col width="15">
              <f7-button fill class="margin-bottom">{{ $t('DoNotUse') }}</f7-button>
            </f7-col>
          </f7-row>
        </f7-block>

      <f7-block  class="texto-sobre-caja">{{$t('FlatHours')}}</f7-block>
      <f7-block class="caja caja-volumen-demandado">
          <f7-row class="text-align-center">
                        <f7-col>
                        <f7-block-title>1</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_1"
                            v-model:value="horas[1][0]"
                            @input = "setCellHoras({pos1: 1, pos2: 0, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>2</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_2"
                            v-model:value="horas[1][1]"
                            @input = "setCellHoras({pos1: 1, pos2: 1, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>3</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_3"
                            v-model:value="horas[1][2]"
                            @input = "setCellHoras({pos1: 1, pos2: 2, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>4</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_4"
                            v-model:value="horas[1][3]"
                            @input = "setCellHoras({pos1: 1, pos2: 3, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>5</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_5"
                            v-model:value="horas[1][4]"
                            @input = "setCellHoras({pos1: 1, pos2: 4, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>6</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_6"
                            v-model:value="horas[1][5]"
                            @input = "setCellHoras({pos1: 1, pos2: 5, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>7</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_7"
                            v-model:value="horas[1][6]"
                            @input = "setCellHoras({pos1: 1, pos2: 6, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>8</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_8"
                            v-model:value="horas[1][7]"
                            @input = "setCellHoras({pos1: 1, pos2: 7, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>9</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_9"
                            v-model:value="horas[1][8]"
                            @input = "setCellHoras({pos1: 1, pos2: 8, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>10</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_10"
                            v-model:value="horas[1][9]"
                            @input = "setCellHoras({pos1: 1, pos2: 9, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>11</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_11"
                            v-model:value="horas[1][10]"
                            @input = "setCellHoras({pos1: 1, pos2: 10, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>

                        <f7-col>
                        <f7-block-title>12</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="horas_llano_12"
                            v-model:value="horas[1][11]"
                            @input = "setCellHoras({pos1: 1, pos2: 11, data:$event.target.value})"
                            >
                            </f7-list-input>
                        </f7-list>
                        </f7-col>
          </f7-row>
          <f7-row>
            <f7-col width="85"></f7-col>
            <f7-col width="15">
              <f7-button fill class="margin-bottom">{{$t('DoNotUse')}}</f7-button>
            </f7-col>
          </f7-row>

      </f7-block>

      <f7-block  class="texto-sobre-caja">{{$t('PeakHours')}}</f7-block>
      <f7-block class="caja caja-volumen-demandado">
      <f7-row class="text-align-center">
                    <f7-col>
                    <f7-block-title>1</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_1"
                        v-model:value="horas[2][0]"
                        @input = "setCellHoras({pos1: 2, pos2: 0, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>2</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_2"
                        v-model:value="horas[2][1]"
                        @input = "setCellHoras({pos1: 2, pos2: 1, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>3</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_3"
                        v-model:value="horas[2][2]"
                        @input = "setCellHoras({pos1: 2, pos2: 2, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>4</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_4"
                        v-model:value="horas[2][3]"
                        @input = "setCellHoras({pos1: 2, pos2: 3, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>5</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_5"
                        v-model:value="horas[2][4]"
                        @input = "setCellHoras({pos1: 2, pos2: 4, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>6</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_6"
                        v-model:value="horas[2][5]"
                        @input = "setCellHoras({pos1: 2, pos2: 5, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>7</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_7"
                        v-model:value="horas[2][6]"
                        @input = "setCellHoras({pos1: 2, pos2: 6, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>8</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_8"
                        v-model:value="horas[2][7]"
                        @input = "setCellHoras({pos1: 2, pos2: 7, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>9</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_9"
                        v-model:value="horas[2][8]"
                        @input = "setCellHoras({pos1: 2, pos2: 8, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>10</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_10"
                        v-model:value="horas[2][9]"
                        @input = "setCellHoras({pos1: 2, pos2: 9, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>11</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_11"
                        v-model:value="horas[2][10]"
                        @input = "setCellHoras({pos1: 2, pos2: 10, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>

                    <f7-col>
                    <f7-block-title>12</f7-block-title>
                    <f7-list inline-labels no-hairlines-md>
                        <f7-list-input
                        type="number"
                        name="hora_punta_12"
                        v-model:value="horas[2][11]"
                        @input = "setCellHoras({pos1: 2, pos2: 11, data:$event.target.value})"
                        >
                        </f7-list-input>
                    </f7-list>
                    </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width=85></f7-col>
        <f7-col width="15">
          <f7-button fill class="margin-bottom">{{$t('DoNotUse')}}</f7-button>
        </f7-col>
      </f7-row>
      </f7-block>

        <f7-row class="text-align-center" style="margin-top: -2%">
            <f7-col width="33">
            </f7-col >
            <f7-col width="20">
            <f7-list inline-labels no-hairlines-md>
              <file-reader
              @load="numbersSplit"
            />
            </f7-list>
            </f7-col>
            <f7-col width="33">
            </f7-col>

        </f7-row>
      <f7-block class="padding-bottom">
          <f7-row>
                <f7-col width="20">
                  <f7-button fill href='/parametrosHidraulicos'>{{$t('PreviousText')}}</f7-button>
                </f7-col>
                <f7-col width="20">
                  <f7-button fill href='/costesInversion/' :disabled = "!validateForm">{{$t('NextText')}}</f7-button>
                </f7-col>
          </f7-row>
      </f7-block>
    </f7-page>

</template>

<script>
import Navbardop from '../components/Navbardop.vue';
import FileReader from '../components/FileReader.vue'
import Api from '../js/Services/Api.js';
import { mapActions, mapState } from 'vuex'
export default {

    data () {
        return {
           fileContent: "prueba",
           }
    },

    components: {
      Navbardop,
      FileReader,
    },

    computed: {
      validateForm() {
        const isHorasValid = this.horas[0].length === 12 && this.horas[1].length === 12 && this.horas[2].length === 12;

        return isHorasValid
      },
      ...mapState('horasMensuales', ['horas']),
    },

    methods: {
      numbersSplit(fileContent){
        let horas = []
        let lineas = fileContent.trim().split("\r\n");
          for (let i = 0; i < lineas.length; i++) {
            let linea = lineas[i];
            horas.push(linea.split(';'));
          }
        this.setHoras(horas);
      },
      ...mapActions('horasMensuales', ['setHoras', 'setCellHoras'])
    },

}
</script>

<style>
#app2 {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.container {
  text-align: left;
}
pre code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
}
#app2 .form {
  text-align: left;
}

.boton-curva-volumen {
  border-radius: 2px;
  width: 150px;
  margin-top: 2px;
  font-size: smaller;
}

.boton-standar {
  justify-content: center;
  border-radius: 2px;
  width: 150px;
  margin-top: 2px;
  font-size: smaller;
}

.caja-volumen-demandado {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  width: 100%;
  margin-top: 0;
}
.caja-evolucion-mensual {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  width: 100%;
  margin-top: 0;
}

.selector {
  margin-right: auto;
  margin-left: -60px;
}

.cuadro-imput-curva-volumen {
  width: 80%;
}
</style>
