<template>
  <f7-page no-margin
    name="home"
  >
    <navBar />
    <f7-block
      class="titular-text"
    >
      <strong>Decision Support System</strong>
    </f7-block>
    <f7-block>
      <centerBlock />
    </f7-block>
  </f7-page>
</template>

<script>
import navBar from '../components/navBar.vue';
import centerBlock from '../components/centerBlock.vue';

export default {
  components: {
    navBar,
    centerBlock,
  },
  data() {
    return {
      navBar,
      centerBlock,
    };
  },
};
</script>

<style lang="scss" scoped>
.titular-text{
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 1;
text-align: center;
color: #42526E;
}

.navbar {
  height: 100px !important;
}
</style>

