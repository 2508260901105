export default {
    namespaced: true,
    state: {
        caracteristicasBomba: {},
        dopirModelOptimizer: {},
        diametroTuberiaImpulsion: 0,
        diametroSecundaria: 0,
    },
  
    getters: {
      caracteristicasBomba: (state) => state.caracteristicasBomba,
    },
  
    actions: {
        setCaracteristicasBomba(context, value) {
            context.commit('setCaracteristicasBomba', value);
            },
        setDopirModelOptimizer(context, value) {
            context.commit('setDopirModelOptimizer', value);
        },
        setDiametroTuberiaImpulsion(context, value) {
            context.commit('setDiametroTuberiaImpulsion', value);
        },
        setDiametroSecundaria(context, value) {
            context.commit('setDiametroSecundaria', value);
        },
    },
  
    mutations: {
        setCaracteristicasBomba(state, value) {
            state.caracteristicasBomba = value;
        },
        setDopirModelOptimizer(state, value) {
            state.dopirModelOptimizer = value;
        },
        setDiametroTuberiaImpulsion(state, value) {
            state.diametroTuberiaImpulsion = value;
        },
        setDiametroSecundaria(state, value) {
            state.diametroSecundaria = value;
        },
    },
  };
  