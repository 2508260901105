<template>
  <f7-page name="home" class="fondo-pantalla-completa">
    <Navbardop
      :title="$t('DOPIRDripTitle')"
    />
    <f7-row>
      <f7-col width="20"><br>
        <f7-button fill bg-color="green" class="link external tooltip-init"
        :data-tooltip="$t('DesktopAppTooltip')"
        href="/media/DOPIR_Solar_MCR2019b.zip">
          {{$t('DesktopAppButton')}}
        </f7-button>
      </f7-col>
    </f7-row>
    <form class="block">
      <f7-block class="texto-sobre-caja">
        {{ $t('MonthlyVolumeDemanded') }}
      </f7-block>

      <f7-block class="caja caja-volumen-demandado">
        <f7-row class="text-align-center">
          <f7-col>
            <f7-block-title>1</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_1" v-model:value="volumenDemandado[0]" @input = "setCellVolumenDemandado({pos: 0, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>


          <f7-col>
            <f7-block-title>2</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_2" v-model:value="volumenDemandado[1]" @input = "setCellVolumenDemandado({pos: 1, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>3</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_3" v-model:value="volumenDemandado[2]" @input = "setCellVolumenDemandado({pos: 2, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>4</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_4" v-model:value="volumenDemandado[3]" @input = "setCellVolumenDemandado({pos: 3, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>5</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_5" v-model:value="volumenDemandado[4]" @input = "setCellVolumenDemandado({pos: 4, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>6</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_6" v-model:value="volumenDemandado[5]" @input = "setCellVolumenDemandado({pos: 5, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>7</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name ="volumen_demandado_7" v-model:value="volumenDemandado[6]" @input = "setCellVolumenDemandado({pos: 6, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>8</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_8" v-model:value="volumenDemandado[7]" @input = "setCellVolumenDemandado({pos: 7, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>9</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_9" v-model:value="volumenDemandado[8]" @input = "setCellVolumenDemandado({pos: 8, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>10</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_10" v-model:value="volumenDemandado[9]" @input = "setCellVolumenDemandado({pos: 9, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>11</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_11" v-model:value="volumenDemandado[10]" @input = "setCellVolumenDemandado({pos: 10, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>12</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="volumen_demandado_12" v-model:value="volumenDemandado[11]" @input = "setCellVolumenDemandado({pos: 11, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>
        </f7-row>

        <f7-row>
          <f7-col width="20">
            <f7-list inline-labels no-hairlines-md>
              <file-reader
                @load="numbersSplit"
              />
            </f7-list>
          </f7-col>

          <f7-row class="text-align-center">
            <f7-col>
              <f7-block>
                {{$t('VolumeCurve')}}
              </f7-block>
            </f7-col>
            <f7-col>
              <f7-block-title>a</f7-block-title>
              <f7-list inline-labels no-hairlines-md>
                <f7-list-input type="number" name="curva_volumen_a" :value="curvaVolumenObject.a" @input = "A($event.target.value)" > </f7-list-input>
              </f7-list>
            </f7-col>

            <f7-col>
              <f7-block-title>b</f7-block-title>
              <f7-list inline-labels no-hairlines-md>
                <f7-list-input type="number" name="curva_volumen_b" :value="curvaVolumenObject.b" @input = "B($event.target.value)" > </f7-list-input>
              </f7-list>
            </f7-col>

            <f7-col width="20">
              <f7-block-title>Tr</f7-block-title>
              <f7-list inline-labels no-hairlines-md>
                <f7-list-input type="number" name="curva_volumen_tr" :value="curvaVolumenObject.tr" @input = "Tr($event.target.value)" > </f7-list-input>
              </f7-list>
            </f7-col>
          </f7-row>
        </f7-row>

        <f7-row>
          <f7-col width="60">
          </f7-col>
          <f7-col width="20">
            <f7-button fill class="margin-bottom btn-small" @click="getDefaultWoodyValues">
              {{$t('WoodyValues')}}
            </f7-button
            >
          </f7-col>
          <f7-col width="20">
            <f7-button fill class="margin-bottom btn-small" @click="getDefaultVegetableValues">
              {{$t('WoodyValues')}}
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>

      <f7-block class="texto-sobre-caja">
        {{$t('MonthlyEvolution')}}
      </f7-block>
      <f7-block class="caja caja-evolucion-mensual">
        <f7-row class="text-align-center">
          <f7-col>
            <f7-block-title>1</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_1" :value="evolucionMensual[0]" @input = "setCellEvolucionMensual({pos: 0, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>2</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_2" :value="evolucionMensual[1]" @input = "setCellEvolucionMensual({pos: 1, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>3</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_3" :value="evolucionMensual[2]" @input = "setCellEvolucionMensual({pos: 2, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>4</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_4" :value="evolucionMensual[3]" @input = "setCellEvolucionMensual({pos: 3, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>5</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_5" :value="evolucionMensual[4]" @input = "setCellEvolucionMensual({pos: 4, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>6</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_6" :value="evolucionMensual[5]" @input = "setCellEvolucionMensual({pos: 5, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>7</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_7" :value="evolucionMensual[6]" @input = "setCellEvolucionMensual({pos: 6, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>8</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_8" :value="evolucionMensual[7]" @input = "setCellEvolucionMensual({pos: 7, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>9</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_9" :value="evolucionMensual[8]" @input = "setCellEvolucionMensual({pos: 8, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>10</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_10" :value="evolucionMensual[9]" @input = "setCellEvolucionMensual({pos: 9, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>11</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_11" :value="evolucionMensual[10]" @input = "setCellEvolucionMensual({pos: 10, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>

          <f7-col>
            <f7-block-title>12</f7-block-title>
            <f7-list inline-labels no-hairlines-md>
              <f7-list-input type="number" name="evolucion_mensual_12" :value="evolucionMensual[11]" @input = "setCellEvolucionMensual({pos: 11, data:$event.target.value})"> </f7-list-input>
            </f7-list>
          </f7-col>
        </f7-row>
        <f7-row class="text-align-center">
          <f7-col width="33">
          </f7-col>
           <f7-col width="20">
            <f7-list inline-labels no-hairlines-md>
              <file-reader
                @load="numbersSplit2"
              />
            </f7-list>
          </f7-col>
          <f7-col width="33">
          </f7-col>

        </f7-row>
      </f7-block>


  <f7-block class="caja block margin-bottom">
      <f7-row>
        <div width="col-30">
          <f7-list inline-labels no-hairlines-md>
            <f7-list-input :label="$t('IrrigatedArea')" type="number" name="superficie_regada" :value="superficieRegada" @input = "setSuperficieRegada( $event.target.value)"/>
            <f7-list-input :label="$t('NumberSectors')" type="number" name="numero_sectores" :value="numeroSectores" @input = "setNumeroSectores($event.target.value)"/>
            <f7-list-input :label="$t('SubunitPressure')" type="number" name="presion_subunidad" :value="presionSubunidad" @input = "setPresionSubunidad($event.target.value)"/>
            <f7-list-input :label="$t('BranchSeparation')" type="number" name="separacion_ramales" :value="separacionRamales" @input = "setSeparacionRamales($event.target.value)"/>
            <f7-list-input :label="$t('EmitterSeparation')" type="number" name="separacion_emisores" :value="separacionEmisores" @input = "setSeparacionEmisores($event.target.value)"/>
            <f7-list-input :label="$t('RatedQ')" type="number" name="q_nominal" :value="qNominal" @input = "setQNominal($event.target.value)"/>
            <f7-list-input :label="$t('DistancePlotElectricalConnection')" type="number" name="distancia_parcela" :value="distanciaParcela"  @input = "setDistanciaParcela($event.target.value)"/>
          </f7-list>
        </div>

        <div class="col-30 text-align-center">
          <f7-block-title>
            {{$t('AquiferData')}}
          </f7-block-title>
          <f7-row>
            <f7-col>
              <f7-block-title>K (m/día)</f7-block-title>
              <f7-list inline-labels no-hairlines-md>
                <f7-list-input type="number" name="datos_acuiferos_k" :value="aquiferObject.k" @input = "K($event.target.value)" />
                <f7-button fill @click="getDefaultAquifer1Values">{{ $t('AquiferText') }} 1</f7-button>
              </f7-list>
            </f7-col>

            <f7-col>
              <f7-block-title>R</f7-block-title>
              <f7-list inline-labels no-hairlines-md>
                <f7-list-input type="number" name="datos_acuiferos_r" :value="aquiferObject.r" @input = "R($event.target.value)"/>
                <f7-button fill @click="getDefaultAquifer2Values">{{ $t('AquiferText') }} 2</f7-button>
              </f7-list>
            </f7-col>
          </f7-row>

        </div>
      </f7-row>
  </f7-block>
      <f7-row>
          <f7-col width="20" class="padding-bottom">
            <f7-button fill href='/horasMensuales/' :disabled = "!validateForm">
              {{$t('NextText')}}
            </f7-button>
          </f7-col>
        </f7-row>
  </form>
  </f7-page>
</template>

<script>
import Navbardop from '../components/Navbardop.vue';
import FileReader from '../components/FileReader.vue';
import Api from '../js/Services/Api.js';
import Framework7 from 'framework7';
import { mapActions, mapState } from 'vuex'

export default {
    data () {
        return {
           fileContent: "prueba",
        }
    },
    components: {
      Navbardop,
      FileReader
    },
    async beforeMount(){
      const temp = await Api.listadoGet();

    },
    computed: {
      validateForm() {
        const isVolumenDemandadoValid = this.volumenDemandado.length === 12;
        const isEvolucionMensualValid = this.evolucionMensual.length === 12;
        const isSuperficieRegadaValid = this.superficieRegada !=="undefined" && this.curvaVolumenObject.a !== null && this.curvaVolumenObject.a !== '';
        const isNumeroSectoresValid = this.numeroSectores !=="undefined" && this.curvaVolumenObject.a !== null && this.curvaVolumenObject.a !== '';
        const isPresionSubunidadValid = this.presionSubunidad !=="undefined" && this.curvaVolumenObject.a !== null && this.curvaVolumenObject.a !== '';
        const isSeparacionRamalesValid = this.separacionRamales !=="undefined" && this.curvaVolumenObject.a !== null && this.curvaVolumenObject.a !== '';
        const isSeparacionEmisoresValid = this.separacionEmisores !=="undefined" && this.curvaVolumenObject.a !== null && this.curvaVolumenObject.a !== '';
        const isQNominalValid = this.qNominal !=="undefined" && this.curvaVolumenObject.a !== null && this.curvaVolumenObject.a !== '';
        const isDistanciaParcelaValid = this.distanciaParcela !=="undefined" && this.curvaVolumenObject.a !== null && this.curvaVolumenObject.a !== '';
        const isCurvaVolumenObjectA = typeof this.curvaVolumenObject.a !=="undefined" && this.curvaVolumenObject.a !== null && this.curvaVolumenObject.a !== '';
        const isCurvaVolumenObjectB = typeof this.curvaVolumenObject.b !=="undefined" && this.curvaVolumenObject.b !== null && this.curvaVolumenObject.b !== '';
        const isCurvaVolumenObjectTr = typeof this.curvaVolumenObject.tr !=="undefined" && this.curvaVolumenObject.tr !== null && this.curvaVolumenObject.tr !== '';
        const isAquiferObjectR = typeof this.aquiferObject.r !=="undefined" && this.aquiferObject.r !== null && this.aquiferObject.r !== '';
        const isAquiferObjectK = typeof this.aquiferObject.k !=="undefined" && this.aquiferObject.k !== null && this.aquiferObject.k !== '';

          return isVolumenDemandadoValid && isEvolucionMensualValid && isSuperficieRegadaValid  && isNumeroSectoresValid && isPresionSubunidadValid && isSeparacionRamalesValid
          && isSeparacionEmisoresValid && isQNominalValid && isDistanciaParcelaValid  && isCurvaVolumenObjectA
          && isCurvaVolumenObjectB && isCurvaVolumenObjectTr && isAquiferObjectR && isAquiferObjectK




      },
      ...mapState('parametrosHidraulicos', ['a', 'b', 'tr','k','r', 'evolucionMensual', 'volumenDemandado',
      'superficieRegada', 'numeroSectores', 'presionSubunidad', 'separacionRamales', 'separacionEmisores',
      'qNominal', 'distanciaParcela', 'curvaVolumenObject', 'aquiferObject']),
    },
    methods: {
        randomNumbers () {
           this.numAleatorio = Math.floor(Math.random() * 50);
           this.numAleatorio2 = Math.floor(Math.random() * 50);
           this.numAleatorio3 = Math.floor(Math.random() * 50);
        } ,
        numbersSplit (fileContent) {
          let lineas = fileContent.trim().split("\n");
          let valoresLineas = [];
            for (let i = 0; i < lineas.length; i++) {
              let linea = lineas[i];
              valoresLineas.push(linea.split(';'));
            }
              this.setVolumenDemandado(valoresLineas[0] )
        },
        numbersSplit2 (fileContent) {
          let lineas = fileContent.trim().split("\n");
          let valoresLineas = [];
            for (let i = 0; i < lineas.length; i++) {
              let linea = lineas[i];
              valoresLineas.push(linea.split(';'));
            }
              this.setEvolucionMensual(valoresLineas[0])
        },

        async getDefaultWoodyValues() {
          try {

          let xhr = await Api.getWoodyValues();
          this.setCurvaVolumenObject(JSON.parse(xhr.response))

           } catch (e) {

          } finally {
          }
        },

        async getDefaultVegetableValues() {
          try {
          let xhrr = await Api.getVegetableValues();
          this.setCurvaVolumenObject(JSON.parse(xhrr.response))
           } catch (e) {

          } finally {
          }
        },

        async getDefaultAquifer1Values() {
          try {
          let xhrr = await Api.getAquifer1Values();
          this.setAquiferObject(JSON.parse(xhrr.response));
           } catch (e) {

          } finally {
          }
        },

        async getDefaultAquifer2Values() {
          try {
          let xhrr = await Api.getAquifer2Values();
          this.setAquiferObject(JSON.parse(xhrr.response));
           } catch (e) {

          } finally {
          }
        },
        ...mapActions('parametrosHidraulicos', ['A', 'B', 'Tr', 'K', 'R','setEvolucionMensual', 'setCellEvolucionMensual', 'setVolumenDemandado', 'setCellVolumenDemandado', 'setSuperficieRegada', 'setNumeroSectores', 'setPresionSubunidad',
          'setSeparacionRamales', 'setSeparacionEmisores', 'setQNominal', 'setDistanciaParcela','setCurvaVolumenObject', 'setAquiferObject']),
    }
}
</script>


<style>
#app2 {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.container {
  text-align: left;
}
pre code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
}
#app2 .form {
  text-align: left;
}

.boton-curva-volumen {
  border-radius: 2px;
  width: 150px;
  margin-top: 2px;
  font-size: smaller;
}
.boton-standar {
  border-radius: 2px;
  width: 150px;
  margin-top: 2px;
  font-size: smaller;
}
.caja-volumen-demandado {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
  width: 50%;
  margin-top: -2%;
}
.caja-evolucion-mensual {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
  width: 50%;
  margin-top: -2%;
}

.selector {
  margin-right: auto;
  margin-left: -60px;
}

.cuadro-imput-curva-volumen {
  width: 50%;
}

.f7-icons {
  color: red;
}
</style>
<style lang="scss" scoped>
.navbar {
  height: 64px !important;
}
</style>

