<template>
    <f7-page name="costesInversion" class="fondo-pantalla-completa">
        <Navbardop
        :title="$t('InvestmentExploitationCosts')"
        />
        <f7-row class="caja caja-primer-bloque padding margin-top">
                <!--PARTE DE ARRIBA-->
                <f7-col>
                    <f7-row>
                            <span>Coste bombas (€)</span>
                    </f7-row>
                    <f7-row>
                        <f7-col>
                            <f7-list inline-labels no-hairlines-md>
                                <span>a</span>
                                <f7-list-input
                                type="number"
                                name="coste_bomba_a"
                                :value = defaultCostObject.coste_bomba_a
                                @input = "setCosteBombaA($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <f7-list inline-labels no-hairlines-md>
                                <span>b</span>
                                <f7-list-input
                                type="number"
                                name="coste_bomba_b"
                                :value = defaultCostObject.coste_bomba_b
                                @input = "setCosteBombaB($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <f7-list inline-labels no-hairlines-md>
                                <span>c</span>
                                <f7-list-input
                                type="number"
                                name="coste_bomba_c"
                                :value = defaultCostObject.coste_bomba_c
                                @input = "setCosteBombaC($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                    </f7-row>
                </f7-col>



                <f7-col>
                    <f7-row>
                        <span>{{ $t('PipeCost') }}</span>
                    </f7-row>
                    <f7-row>
                            <f7-col>
                                <f7-row>
                                    <f7-col style="margin-top:40px">
                                        <span>{{ $t('ImpulsionText') }}</span>
                                    </f7-col>
                                    <f7-col>
                                        <f7-list inline-labels no-hairlines-md>
                                            <f7-list-input
                                            type="number"
                                            name="impulsion_a"
                                            :value = defaultCostObject.impulsion_a
                                            @input = "setImpulsionA($event.target.value)"
                                            >
                                            </f7-list-input>
                                        </f7-list>
                                    </f7-col>
                                    <f7-col>
                                        <f7-list inline-labels no-hairlines-md>
                                            <f7-list-input
                                            type="number"
                                            name="impulsion_b"
                                            :value = defaultCostObject.impulsion_b
                                            @input = "setImpulsionB($event.target.value)"
                                            >
                                            </f7-list-input>
                                        </f7-list>
                                    </f7-col>
                                </f7-row>

                                <f7-row>
                                    <f7-col style="margin-top:40px">
                                        <span>{{ $t('SecondaryText') }}</span>
                                    </f7-col>
                                    <f7-col>
                                        <f7-list inline-labels no-hairlines-md>
                                            <f7-list-input
                                            type="number"
                                            name="secundaria_a"
                                            :value = defaultCostObject.secundaria_a
                                            @input = "setSecundariaA($event.target.value)"
                                            >
                                            </f7-list-input>
                                        </f7-list>
                                    </f7-col>
                                    <f7-col>
                                        <f7-list inline-labels no-hairlines-md>
                                            <f7-list-input
                                            type="number"
                                            name="secundaria_b"
                                            :value = defaultCostObject.secundaria_b
                                            @input = "setSecundariaB($event.target.value)"
                                            >
                                            </f7-list-input>
                                        </f7-list>
                                    </f7-col>
                                </f7-row>

                            </f7-col>
                    </f7-row>
                </f7-col>
        </f7-row>

        <!--TRES BLOQUES INTERMEDIOS-->
        <f7-row>
            <div class="col-40">
                <f7-block class="texto-sobre-caja">{{$t('EnergyCosts')}}</f7-block>
                <f7-block class="caja caja-coste-energia" >
                    <f7-row>
                        <f7-col style="padding-top:60px;">
                            <f7-block-title>{{$t('ValleyText')}}</f7-block-title>
                            <f7-block-title>{{$t('FlatText')}}</f7-block-title>
                            <f7-block-title>{{$t('CostText')}}</f7-block-title>
                        </f7-col>

                        <f7-col>
                            <f7-block-title>{{$t('PotencyText')}}<br/>{{$t('UnitYear')}}</f7-block-title>
                            <f7-list inline-labels no-hairlines-md>
                                <f7-list-input type="number" name="potencia_coste_valle" :value = defaultCostObject.potencia_coste_valle @input = "setPotenciaCosteValle($event.target.value)"></f7-list-input>
                                <f7-list-input type="number" name="potencia_coste_llano" :value = defaultCostObject.potencia_coste_llano @input = "setPotenciaCosteLlano($event.target.value)"></f7-list-input>
                                <f7-list-input type="number" name="potencia_coste" :value = defaultCostObject.potencia_coste @input = "setPotenciaCoste($event.target.value)"></f7-list-input>
                            </f7-list>
                        </f7-col>

                        <f7-col>
                            <f7-block-title>{{$t('EnergyText')}}<br/>{{$t('UnitYear')}}</f7-block-title>
                            <f7-list inline-labels no-hairlines-md>
                                <f7-list-input
                                type="number"
                                name="energia_coste_valle"
                                :value = defaultCostObject.energia_coste_valle @input = "setEnergiaCosteValle($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                type="number"
                                name="energia_coste_llano"
                                :value = defaultCostObject.energia_coste_llano @input = "setEnergiaCosteLlano($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                type="number"
                                name="energia_coste"
                                :value = defaultCostObject.energia_coste @input = "setEnergiaCoste($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>

                        <f7-col>
                            <f7-block-title>{{ $t('TaxesText') }}<br>(%)</f7-block-title>
                            <f7-list inline-labels no-hairlines-md>
                                <f7-list-input
                                type="number"
                                name="impuestos"
                                :value = defaultCostObject.impuestos
                                @input = "setImpuesto($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>

                            <f7-block-title>Alquiler</f7-block-title>
                            <f7-list inline-labels no-hairlines-md>
                                <f7-list-input
                                type="number"
                                name="alquiler"
                                :value = defaultCostObject.alquiler
                                @input = "setAlquiler($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                    </f7-row>
                </f7-block>

                <f7-row>
                    <f7-col width="40" class="center col-padding-top">
                        <f7-block-title>{{$t('TransformerCost')}}</f7-block-title>
                    </f7-col>

                    <f7-col width="20">
                        <f7-block-title>a</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="transformador_a"
                            :value =  defaultCostObject.transformador_a
                            @input = "setTransformadorA($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>

                    <f7-col width="20">
                        <f7-block-title>b</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="transformador_b"
                            :value =  defaultCostObject.transformador_b
                            @input = "setTransformadorB($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>

                    <f7-col width="20">
                        <f7-block-title>c</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="transformador_c"
                            :value =  defaultCostObject.transformador_c
                            @input = "setTransformadorC($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-row>
                    <f7-col width="40" class="center">
                        <f7-block-title>{{ $t('PowerLineCost') }}</f7-block-title>
                    </f7-col>

                    <f7-col width="60">
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="coste_linea_electrica"
                            :value =  defaultCostObject.coste_linea_electrica
                            @input = "setCosteLineaElectrica($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-row>
                    <f7-col width="40" class="center col-padding-top">
                        <f7-block-title>{{$t('HydraulicValveCost')}}</f7-block-title>
                    </f7-col>

                    <f7-col width="20">
                        <f7-block-title>a</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="valvula_hidraulica_a"
                            :value =  defaultCostObject.valvula_hidraulica_a
                            @input = "setValvulaHidraulicaA($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>

                    <f7-col width="20">
                        <f7-block-title>b</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="valvula_hidraulica_b"
                            :value =  defaultCostObject.valvula_hidraulica_b
                            @input = "setValvulaHidraulicaB($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>

                    <f7-col width="20">
                        <f7-block-title>c</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="valvula_hidraulica_c"
                            :value =  defaultCostObject.valvula_hidraulica_c
                            @input = "setValvulaHidraulicaC($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-row>
                    <f7-col width="40" class="center col-padding-top">
                        <f7-block-title>{{$t('FilterCost')}}</f7-block-title>
                    </f7-col>

                    <f7-col width="20">
                        <f7-block-title>a</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="coste_filtro_a"
                            :value =  defaultCostObject.coste_filtro_a
                            @input = "setCosteFiltroA($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>

                    <f7-col width="20">
                        <f7-block-title>b</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="coste_filtro_b"
                            :value =  defaultCostObject.coste_filtro_b
                            @input = "setCosteFiltroB($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>

                    <f7-col width="20">
                        <f7-block-title>c</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="coste_filtro_c"
                            :value =  defaultCostObject.coste_filtro_c
                            @input = "setCosteFiltroC($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-row>
                    <f7-col width="25" class="center col-padding-top">
                        <f7-block-title>{{$t('CostText')}}<br>{{$t('SubunitText')}}</f7-block-title>
                    </f7-col>

                    <f7-col width="15">
                        <f7-block-title>a</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="coste_subunidad_a"
                            :value = subunitCostObject.a
                            @input = "A($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>

                    <f7-col width="15">
                        <f7-block-title>b</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="coste_subunidad_b"
                            :value= subunitCostObject.b
                            @input = "B($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>

                    <f7-col width="15">
                        <f7-block-title>c</f7-block-title>
                        <f7-list inline-labels no-hairlines-md>
                            <f7-list-input
                            type="number"
                            name="coste_subunidad_c"
                            :value = subunitCostObject.c
                            @input = "C($event.target.value)"
                            >
                            </f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col width="20" class="col-padding-top">
                        <f7-button class="margin-bottom" fill @click="getDefaultVegetableSubunitCostValues">{{ $t('VineyardText') }}</f7-button>
                        <f7-button fill @click="getDefaultVineyardSubunitCostValues">{{ $t('VegetablesText') }}</f7-button>
                    </f7-col>
                </f7-row>

                <f7-row>
                    <f7-col width="10"></f7-col>
                    <f7-col width="35" class="margin-top">
                        <f7-button fill @click="getDefaultCostValues">{{$t('DefaultValues')}}</f7-button>
                    </f7-col>
                    <f7-col width="35" class="margin-top">
                        <f7-button fill @click="getDefaultDieselCostValues">{{$t('GeneratorSet')}}</f7-button>
                    </f7-col>
                    <f7-col width="20"></f7-col>
                </f7-row>


            </div>

            <div class="col-20">
                <f7-block class="texto-sobre-caja">{{$t('CapitalUpdate')}}</f7-block>
                <f7-block class="caja caja-coste-energia">
                    <f7-row>
                        <f7-col>
                            <f7-list inline-labels no-hairlines-md>
                                <f7-list-input
                                :label="$t('UsefulLife')"
                                type="number"
                                name="vida_util"
                                :value =  defaultCostObject.vida_util
                                @input = "setVidaUtil($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                :label="$t('InterestText')"
                                type="number"
                                name="interes"
                                :value =  defaultCostObject.interes
                                @input = "setInteres($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                :label="$t('EnergyRate')"
                                type="number"
                                name="tasa_energia"
                                :value =  defaultCostObject.tasa_energia
                                @input = "setTasaEnergia($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                :label="$t('VATText')"
                                type="number"
                                name="iva"
                                :value =  defaultCostObject.iva
                                @input = "setIva($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                    </f7-row>
                </f7-block>

            </div>
            <div class="col-40">
                <f7-block class="texto-sobre-caja">{{$t('ElectricalInstallation')}}</f7-block>
                <f7-block class="caja caja-coste-energia">
                    <f7-row>
                        <f7-col width="40" class="center col-padding-top">
                            <f7-block-title>{{$t('CablingText')}}</f7-block-title>
                            <f7-block-title>{{$t('BoxText')}}</f7-block-title>
                            <f7-block-title>{{ $t('StarterText') }}</f7-block-title>
                            <f7-block-title>{{$t('ProgrammerAuxiliaries')}}</f7-block-title>
                        </f7-col>

                        <f7-col width="20">
                            <f7-block-title>a</f7-block-title>
                            <f7-list inline-labels no-hairlines-md>
                                <f7-list-input
                                type="number"
                                name="cableado_a"
                                :value =  defaultCostObject.cableado_a
                                @input = "setCableadoA($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                type="number"
                                name="cuadro_a"
                                :value =  defaultCostObject.cuadro_a
                                @input = "setCuadroA($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                type="number"
                                name="arrancador_a"
                                :value =  defaultCostObject.arrancador_a
                                @input = "setArrancadorA($event.target.value)"
                                >
                                </f7-list-input>
                               <f7-list-input
                                type="number"

                                name="programador_auxiliares"
                                :value =  defaultCostObject.programador_auxiliares
                                @input = "setProgramadorAuxiliares($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>

                        <f7-col width="20">
                            <f7-block-title>b</f7-block-title>
                            <f7-list inline-labels no-hairlines-md>
                                <f7-list-input
                                type="number"
                                name="cableado_b"
                                :value =  defaultCostObject.cableado_b
                                @input = "setCableadoB($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                type="number"
                                name="cuadro_b"
                                :value =  defaultCostObject.cuadro_b
                                @input = "setCuadroB($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                type="number"
                                name="arrancador_b"
                                :value =  defaultCostObject.arrancador_b
                                @input = "setArrancadorB($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>

                        <f7-col width="20">
                            <f7-block-title>c</f7-block-title>
                            <f7-list inline-labels no-hairlines-md>
                                <f7-list-input
                                type="number"
                                name="cableado_c"
                                :value =  defaultCostObject.cableado_c
                                @input = "setCableadoC($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input disabled
                                type="number"
                                name="cuadro_c"

                                >
                                </f7-list-input>
                                <f7-list-input
                                type="number"
                                name="arrancador_c"
                                :value =  defaultCostObject.arrancador_c
                                @input = "setArrancadorC($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                    </f7-row>
                </f7-block>

                <f7-block class="texto-sobre-caja">{{ $t('ProbeText') }}</f7-block>
                <f7-block class="caja caja-coste-energia">
                    <f7-row>
                        <f7-col class="center">
                            <f7-block-title>{{ $t('TransportationText') }}</f7-block-title>
                            <f7-block-title>{{ $t('ProjectText') }}</f7-block-title>
                            <f7-block-title>{{ $t('DrillingText') }}</f7-block-title>
                        </f7-col>

                        <f7-col>
                            <f7-list inline-labels no-hairlines-md>
                                <f7-list-input
                                type="number"
                                name="transporte"
                                :value =  defaultCostObject.transporte
                                @input = "setTransporte($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                type="number"
                                name="proyecto"
                                :value =  defaultCostObject.proyecto
                                @input = "setProyecto($event.target.value)"
                                >
                                </f7-list-input>
                                <f7-list-input
                                type="number"
                                name="perforacion"
                                :value =  defaultCostObject.perforacion
                                @input = "setPerforacion($event.target.value)"
                                >
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                    </f7-row>
                </f7-block>
            </div>


        </f7-row>
        <f7-block>
                    <f7-row class="padding-bottom">
                            <f7-col width="20">
                                <f7-button fill  href='/horasMensuales/'>{{ $t('PreviousText') }}</f7-button>
                            </f7-col>
                            <f7-col width="20">
                            </f7-col>
                            <f7-col width="20">
                                <f7-button href='/caracteristicasBomba/' fill :disabled = "!validateForm" @click="sendDopirModel()">
                                  {{ $t('NextText') }}
                                </f7-button>
                            </f7-col>

                    </f7-row>
        </f7-block>

</f7-page>
</template>

<script>
import Navbardop from '../components/Navbardop.vue'
import Api from '../js/Services/Api.js';
import { mapActions, mapState } from 'vuex'
export default {
    data() {
		return {
		}
	  },

    components: {
      Navbardop
    },

    computed: {
      validateForm() {
          const isSubunitCostObjectA = typeof this.subunitCostObject.a !=="undefined" && this.subunitCostObject.a !== null && this.subunitCostObject.a !== '';
          const isSubunitCostObjectB = typeof this.subunitCostObject.b !=="undefined" && this.subunitCostObject.b !== null && this.subunitCostObject.b !== '';
          const isSubunitCostObjectC = typeof this.subunitCostObject.c !=="undefined" && this.subunitCostObject.c !== null && this.subunitCostObject.c !== '';

          const isDefaultCostObjectBombaA = typeof this.defaultCostObject.coste_bomba_a !=="undefined" && this.defaultCostObject.coste_bomba_a !== null && this.defaultCostObject.coste_bomba_a !== '';
          const isDefaultCostObjectBombaB = typeof this.defaultCostObject.coste_bomba_b !=="undefined" && this.defaultCostObject.coste_bomba_b !== null && this.defaultCostObject.coste_bomba_b !== '';
          const isDefaultCostObjectBombaC = typeof this.defaultCostObject.coste_bomba_c !=="undefined" && this.defaultCostObject.coste_bomba_c !== null && this.defaultCostObject.coste_bomba_c !== '';

          const isDefaultCostObjectImpulsionA= typeof this.defaultCostObject.impulsion_a !=="undefined" && this.defaultCostObject.impulsion_a !== null && this.defaultCostObject.impulsion_a !== '';
          const isDefaultCostObjectImpulsionB= typeof this.defaultCostObject.impulsion_b !=="undefined" && this.defaultCostObject.impulsion_b !== null && this.defaultCostObject.impulsion_b !== '';
          const isDefaultCostObjectSecundariaA= typeof this.defaultCostObject.secundaria_a !=="undefined" && this.defaultCostObject.secundaria_a !== null && this.defaultCostObject.secundaria_a !== '';
          const isDefaultCostObjectSecundariaB= typeof this.defaultCostObject.secundaria_b !=="undefined" && this.defaultCostObject.secundaria_b !== null && this.defaultCostObject.secundaria_b !== '';

          const isDefaultCostObjectPotenciaCosteValle= typeof this.defaultCostObject.potencia_coste_valle !=="undefined" && this.defaultCostObject.potencia_coste_valle !== null && this.defaultCostObject.potencia_coste_valle !== '';
          const isDefaultCostObjectPotenciaCosteLlano= typeof this.defaultCostObject.potencia_coste_llano !=="undefined" && this.defaultCostObject.potencia_coste_llano !== null && this.defaultCostObject.potencia_coste_llano !== '';
          const isDefaultCostObjectPotenciaCoste= typeof this.defaultCostObject.potencia_coste !=="undefined" && this.defaultCostObject.potencia_coste !== null && this.defaultCostObject.potencia_coste !== '';

          const isDefaultCostObjectEnergiaCosteValle= typeof this.defaultCostObject.energia_coste_valle !=="undefined" && this.defaultCostObject.energia_coste_valle !== null && this.defaultCostObject.energia_coste_valle !== '';
          const isDefaultCostObjectEnergiaCosteLlano= typeof this.defaultCostObject.energia_coste_llano !=="undefined" && this.defaultCostObject.energia_coste_llano !== null && this.defaultCostObject.energia_coste_llano !== '';
          const isDefaultCostObjectEnergiaCoste= typeof this.defaultCostObject.energia_coste !=="undefined" && this.defaultCostObject.energia_coste !== null && this.defaultCostObject.energia_coste !== '';

          const isDefaultCostObjectImpuestos= typeof this.defaultCostObject.impuestos !=="undefined" && this.defaultCostObject.impuestos !== null && this.defaultCostObject.impuestos !== '';
          const isDefaultCostObjectAlquiler= typeof this.defaultCostObject.alquiler !=="undefined" && this.defaultCostObject.alquiler !== null && this.defaultCostObject.alquiler !== '';

          const isDefaultCostObjectTransformadorA= typeof this.defaultCostObject.transformador_a !=="undefined" && this.defaultCostObject.transformador_a !== null && this.defaultCostObject.transformador_a !== '';
          const isDefaultCostObjectTransformadorB= typeof this.defaultCostObject.transformador_b !=="undefined" && this.defaultCostObject.transformador_b !== null && this.defaultCostObject.transformador_b !== '';
          const isDefaultCostObjectTransformadorC= typeof this.defaultCostObject.transformador_c !=="undefined" && this.defaultCostObject.transformador_c !== null && this.defaultCostObject.transformador_c !== '';

          const isDefaultCostObjectValvulaA= typeof this.defaultCostObject.valvula_hidraulica_a !=="undefined" && this.defaultCostObject.valvula_hidraulica_a !== null && this.defaultCostObject.valvula_hidraulica_a !== '';
          const isDefaultCostObjectValvulaB= typeof this.defaultCostObject.valvula_hidraulica_b !=="undefined" && this.defaultCostObject.valvula_hidraulica_b !== null && this.defaultCostObject.valvula_hidraulica_b !== '';
          const isDefaultCostObjectValvulaC= typeof this.defaultCostObject.valvula_hidraulica_c !=="undefined" && this.defaultCostObject.valvula_hidraulica_c !== null && this.defaultCostObject.valvula_hidraulica_c !== '';

          const isDefaultCostObjectFiltroA= typeof this.defaultCostObject.coste_filtro_a !=="undefined" && this.defaultCostObject.coste_filtro_a !== null && this.defaultCostObject.coste_filtro_a !== '';
          const isDefaultCostObjectFiltroB= typeof this.defaultCostObject.coste_filtro_b !=="undefined" && this.defaultCostObject.coste_filtro_b !== null && this.defaultCostObject.coste_filtro_b !== '';
          const isDefaultCostObjectFiltroC= typeof this.defaultCostObject.coste_filtro_c !=="undefined" && this.defaultCostObject.coste_filtro_c !== null && this.defaultCostObject.coste_filtro_c !== '';

          const isDefaultCostObjectVidaUtil= typeof this.defaultCostObject.vida_util !=="undefined" && this.defaultCostObject.vida_util !== null && this.defaultCostObject.vida_util !== '';
          const isDefaultCostObjectInteres= typeof this.defaultCostObject.interes !=="undefined" && this.defaultCostObject.interes !== null && this.defaultCostObject.interes !== '';
          const isDefaultCostObjectTasaEnergia= typeof this.defaultCostObject.tasa_energia !=="undefined" && this.defaultCostObject.tasa_energia !== null && this.defaultCostObject.tasa_energia !== '';
          const isDefaultCostObjectIva= typeof this.defaultCostObject.iva !=="undefined" && this.defaultCostObject.iva !== null && this.defaultCostObject.iva !== '';
          const isDefaultCostObjectTransporte= typeof this.defaultCostObject.transporte !=="undefined" && this.defaultCostObject.transporte !== null && this.defaultCostObject.transporte !== '';
          const isDefaultCostObjectProyecto= typeof this.defaultCostObject.proyecto !=="undefined" && this.defaultCostObject.proyecto !== null && this.defaultCostObject.proyecto !== '';
          const isDefaultCostObjectPerforacion= typeof this.defaultCostObject.perforacion !=="undefined" && this.defaultCostObject.perforacion !== null && this.defaultCostObject.perforacion !== '';

          const isDefaultCostObjectCableadoA= typeof this.defaultCostObject.cableado_a !=="undefined" && this.defaultCostObject.cableado_a !== null && this.defaultCostObject.cableado_a !== '';
          const isDefaultCostObjectCuadroA= typeof this.defaultCostObject.cuadro_a !=="undefined" && this.defaultCostObject.cuadro_a !== null && this.defaultCostObject.cuadro_a !== '';
          const isDefaultCostObjectArrancadorA= typeof this.defaultCostObject.arrancador_a !=="undefined" && this.defaultCostObject.arrancador_a !== null && this.defaultCostObject.arrancador_a !== '';

          const isDefaultCostObjectCableadoB= typeof this.defaultCostObject.cableado_b !=="undefined" && this.defaultCostObject.cableado_b !== null && this.defaultCostObject.cableado_b !== '';
          const isDefaultCostObjectCuadroB= typeof this.defaultCostObject.cuadro_b !=="undefined" && this.defaultCostObject.cuadro_b !== null && this.defaultCostObject.cuadro_b !== '';
          const isDefaultCostObjectArrancadorB= typeof this.defaultCostObject.arrancador_b !=="undefined" && this.defaultCostObject.arrancador_b !== null && this.defaultCostObject.arrancador_b !== '';

          const isDefaultCostObjectCableadoC= typeof this.defaultCostObject.cableado_c !=="undefined" && this.defaultCostObject.cableado_c !== null && this.defaultCostObject.cableado_c !== '';
          const isDefaultCostObjectArrancadorC= typeof this.defaultCostObject.arrancador_c !=="undefined" && this.defaultCostObject.arrancador_c !== null && this.defaultCostObject.arrancador_c !== '';

          const isDefaultCostObjectProgramadorAuxiliar= typeof this.defaultCostObject.programador_auxiliares !=="undefined" && this.defaultCostObject.programador_auxiliares !== null && this.defaultCostObject.programador_auxiliares !== '';
          const isDefaultCostObjectCosteLineaElectrica= typeof this.defaultCostObject.coste_linea_electrica !=="undefined" && this.defaultCostObject.coste_linea_electrica !== null && this.defaultCostObject.coste_linea_electrica !== '';



          return isSubunitCostObjectA && isSubunitCostObjectB && isSubunitCostObjectC && isDefaultCostObjectBombaA && isDefaultCostObjectBombaB && isDefaultCostObjectBombaC
                 && isDefaultCostObjectImpulsionA && isDefaultCostObjectImpulsionB && isDefaultCostObjectSecundariaA && isDefaultCostObjectSecundariaB
                 && isDefaultCostObjectPotenciaCosteValle && isDefaultCostObjectPotenciaCosteLlano && isDefaultCostObjectPotenciaCoste
                 && isDefaultCostObjectEnergiaCosteValle && isDefaultCostObjectEnergiaCosteLlano && isDefaultCostObjectEnergiaCoste
                 && isDefaultCostObjectImpuestos && isDefaultCostObjectAlquiler && isDefaultCostObjectTransformadorA && isDefaultCostObjectTransformadorB && isDefaultCostObjectTransformadorC
                 && isDefaultCostObjectValvulaA && isDefaultCostObjectValvulaB && isDefaultCostObjectValvulaC && isDefaultCostObjectFiltroA && isDefaultCostObjectFiltroB
                 && isDefaultCostObjectFiltroC &&isDefaultCostObjectVidaUtil && isDefaultCostObjectInteres && isDefaultCostObjectTasaEnergia && isDefaultCostObjectIva
                 && isDefaultCostObjectTransporte &&  isDefaultCostObjectProyecto && isDefaultCostObjectPerforacion && isDefaultCostObjectCableadoA && isDefaultCostObjectCuadroA
                 && isDefaultCostObjectArrancadorA && isDefaultCostObjectCableadoB && isDefaultCostObjectCuadroB && isDefaultCostObjectArrancadorB && isDefaultCostObjectCableadoC
                 && isDefaultCostObjectArrancadorC && isDefaultCostObjectProgramadorAuxiliar && isDefaultCostObjectCosteLineaElectrica

      },
      ...mapState('costesInversion', ['subunitCostObject', 'defaultCostObject']),
      ...mapState('horasMensuales', ['horas']),
      ...mapState('parametrosHidraulicos', ['a', 'b', 'tr','k','r', 'evolucionMensual', 'volumenDemandado',
      'superficieRegada', 'numeroSectores', 'presionSubunidad', 'separacionRamales', 'separacionEmisores',
      'qNominal', 'distanciaParcela', 'curvaVolumenObject', 'aquiferObject']),
    },

    methods: {

        async getDefaultVegetableSubunitCostValues() {
          try {
          let xhrr = await Api.getVegetableSubunitCostValues();
          this.setSubunitCostObject(JSON.parse(xhrr.response));
           } catch (e) {

          } finally {
          }
        },

        async getDefaultVineyardSubunitCostValues() {
          try {
          let xhrr = await Api.getVineyardSubunitCostValues();
          this.setSubunitCostObject(JSON.parse(xhrr.response));
           } catch (e) {

          } finally {
          }
        },

        async getDefaultCostValues() {
          try {
          let xhrr = await Api.getDefaultCostValues();
          this.setDefaultCostObject(JSON.parse(xhrr.response));
           } catch (e) {

          } finally {
          }
        },

        async getDefaultDieselCostValues() {
          try {
          let xhrr = await Api.getDefaultDieselCostValues();
          this.defaultCostObject = JSON.parse(xhrr.response);
          // this.curvaVolumenObject = JSON.parse(Api.getWoodyValues());
           } catch (e) {

          } finally {
          }
        },
        async sendDopirModel() {
            this.$f7.preloader.show();
            try {
            const newDopirModel = {
                volumenDemandado: this.volumenDemandado,
                evolucionMensual: this.evolucionMensual,
                superficieRegada: this.superficieRegada,
                datosAcuiferos: [this.aquiferObject.k, this.aquiferObject.r],
                numeroSectores: this.numeroSectores,
                presionSubunidad: this.presionSubunidad,
                separacionRamales: this.separacionRamales,
                separacionEmisores: this.separacionEmisores,
                qNominal: this.qNominal,
                distanciaParcela: this.distanciaParcela,
                volumenCurvaA: this.curvaVolumenObject.a,
                volumenCurvaB: this.curvaVolumenObject.b,
                tr: this.curvaVolumenObject.tr,
                HorasValle: this.horas[0],
                HorasLlano: this.horas[1],
                HoraPunta: this.horas[2],
                CosteBombas: [this.defaultCostObject.coste_bomba_a, this.defaultCostObject.coste_bomba_b, this.defaultCostObject.coste_bomba_c],
                CosteTuberiasImpulsion: [this.defaultCostObject.impulsion_a,this.defaultCostObject.impulsion_b],
                CosteTuberiaSecundaria: [this.defaultCostObject.secundaria_a,this.defaultCostObject.secundaria_b],
                CosteEnergia: [this.defaultCostObject.potencia_coste_valle, this.defaultCostObject.potencia_coste_llano, this.defaultCostObject.potencia_coste, this.defaultCostObject.energia_coste_valle,
                 this.defaultCostObject.energia_coste_llano, this.defaultCostObject.energia_coste, this.defaultCostObject.impuestos, this.defaultCostObject.alquiler],
                CosteTransformador: [this.defaultCostObject.transformador_a, this.defaultCostObject.transformador_b, this.defaultCostObject.transformador_c],
                CosteLineaElectrica: this.defaultCostObject.coste_linea_electrica,
                CostesValvulaHidraulica: [this.defaultCostObject.valvula_hidraulica_a, this.defaultCostObject.valvula_hidraulica_b, this.defaultCostObject.valvula_hidraulica_c],
                CostesFiltro: [this.defaultCostObject.coste_filtro_a, this.defaultCostObject.coste_filtro_b, this.defaultCostObject.coste_filtro_c],
                CosteSubunidad: [this.subunitCostObject.a, this.subunitCostObject.b, this.subunitCostObject.c],
                ActualizacionCapital: [this.defaultCostObject.vida_util, this.defaultCostObject.interes, this.defaultCostObject.tasa_energia, this.defaultCostObject.iva],
                Sondeo: [this.defaultCostObject.transporte, this.defaultCostObject.proyecto, this.defaultCostObject.perforacion],
                InstalacionElectrica:[ this.defaultCostObject.cableado_a, this.defaultCostObject.cableado_b, this.defaultCostObject.cableado_c, this.defaultCostObject.cuadro_a,this.defaultCostObject.cuadro_b,
                this.defaultCostObject.arrancador_a, this.defaultCostObject.arrancador_b, this.defaultCostObject.arrancador_c, this.defaultCostObject.programador_auxiliares]





            };
            const respuesta = await Api.requestDopirModel(newDopirModel);
            this.setCaracteristicasBomba(JSON.parse(respuesta.response))
            } catch (reason) {
            console.log({reason})
            } finally {
            this.$f7.preloader.hide();
            }
        },
        ...mapActions('caracteristicasBomba', ['setCaracteristicasBomba']),
        ...mapActions('costesInversion', ['setDefaultCostObject', 'setSubunitCostObject']),
    }
}
</script>


<style>
.boton-curva-volumen {
  border-radius: 2px;
  width: 150px;
  margin-top: 2px;
  font-size: smaller;
}

.boton-standar {
  border-radius: 2px;
  width: 150px;
  margin-top: 2px;
  font-size: smaller;
}
.caja-coste-energia {
  border: 1px;
  border-style: solid;
  border-color: rgb(175, 175, 175);
  height: auto;
}

.selector {
  margin-right: auto;
  margin-left: -60px;
}

.cuadro-imput-curva-volumen {
  width: 80%;
}
</style>

