export default {
    namespaced: true,
    state: {
      horas:[[],[],[]],
     
    },
  
    getters: {

      horas: (state) => state.horas
    },
  
    actions: {
        
        setCellHoras(context, value) {
          context.commit('setCellHoras', value);
        },
        setHoras(context, value) {
          context.commit('setHoras', value);
        },
    },
  
    mutations: {
      
      setCellHoras(state, value) { 
        state.horas[value.pos1][value.pos2] = value.data;
       

      },

      setHoras(state, value) {
        state.horas = value;
      },
    },
  };
  