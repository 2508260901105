export default {
    namespaced: true,
    state: {
        subunitCostObject: {},
        defaultCostObject: {}
    },
  
    getters: {
      a: (state) => state.a,
      b: (state) => state.b,
      c: (state) => state.tr,
      coste_bomba_a: (state) => state.coste_bomba_a,
      coste_bomba_b: (state) => state.coste_bomba_b,
      coste_bomba_c: (state) => state.coste_bomba_c,
      impulsion_a: (state) => state.impulsion_a,
      impulsion_b: (state) => state.impulsion_b,
      secundaria_a: (state) => state.secundaria_a,
      secundaria_b: (state) => state.secundaria_b,
      potencia_coste_valle: (state) => state.potencia_coste_valle,
      potencia_coste_llano: (state) => state.potencia_coste_llano,
      potencia_coste:(state) => state.potencia_coste,
      energia_coste_valle: (state) => state.energia_coste_valle,
      energia_coste_llano: (state) => state.energia_coste_llano,
      energia_coste:(state) => state.energia_coste,
      impuesto:(state) => state.impuesto,
      alquiler:(state)=> state.alquiler,
      transformador_a:(state)=> state.transformador_a,
      transformador_b:(state)=> state.transformador_b,
      transformador_c:(state)=> state.transformador_c,
      valvula_hidraulica_a:(state)=> state.valvula_hidraulica_a,
      valvula_hidraulica_b:(state)=> state.valvula_hidraulica_b,
      valvula_hidraulica_c:(state)=> state.valvula_hidraulica_c,
      coste_linea_electrica:(state)=> state.coste_linea_electrica,
      coste_filtro_a:(state)=> state.valvula_hidraulica_a,
      coste_filtro_b:(state)=> state.valvula_hidraulica_b,
      coste_filtro_c:(state)=> state.valvula_hidraulica_c,
      vida_util:(state)=> state.vida_util,
      interes:(state)=> state.interes,
      tasa_energia:(state)=> state.tasa_energia,
      iva :(state)=> state.iva,
      transporte:(state)=> state.transporte,
      proyecto :(state)=> state.proyecto,
      perforacion:(state)=> state.perforacion,
      cableado_a:(state)=> state.cableado_a,
      cuadro_a:(state)=> state.cuadro_,
      arrancador_a:(state)=> state.arrancador_a,
      cableado_b:(state)=> state.cableado_b,
      cuadro_b:(state)=> state.cuadro_b,
      arrancador_b:(state)=> state.arrancador_b,
      cableado_c:(state)=> state.cableado_c,
      arrancador_c:(state)=> state.arrancador_c,
      programadorAuxiliares:(state)=> state.programador_auxiliares

    },
  
    actions: {
        A(context, value) {
          context.commit('A', value);
        },
        B(context, value) {
          context.commit('B', value);
        },
        C(context, value) {
          context.commit('C', value);
        },

        setCosteBombaA(context, value) {
            context.commit('setCosteBombaA', value);
        },

        setCosteBombaB(context, value) {
        context.commit('setCosteBombaB', value);
        },

        setCosteBombaC(context, value) {
            context.commit('setCosteBombaC', value);
        },

        setImpulsionA(context, value) {
            context.commit('setImpulsionA', value);
        },

        setImpulsionB(context, value) {
            context.commit('setImpulsionB', value);
        },

        setSecundariaA(context, value) {
            context.commit('setSecundariaA', value);
        },

        setSecundariaB(context, value) {
            context.commit('setSecundariaB', value);
        },

        setPotenciaCosteValle(context, value) {
            context.commit('setPotenciaCosteValle', value);
        },

        setPotenciaCosteLlano(context, value) {
            context.commit('setPotenciaCosteLlano', value);
        },

        setPotenciaCoste(context, value) {
            context.commit('setPotenciaCoste', value);
        },

        setEnergiaCosteValle(context, value) {
            context.commit('setEnergiaCosteValle', value);
        },

        setEnergiaCosteLlano(context, value) {
            context.commit('setEnergiaCosteLlano', value);
        },

        setEnergiaCoste(context, value) {
            context.commit('setEnergiaCoste', value);
        },

        setImpuesto(context, value) {
            context.commit('setImpuesto', value);
        },

        setAlquiler(context, value) {
            context.commit('setAlquiler', value);
        },

        setTransformadorA(context, value) {
            context.commit('setTransformadorA', value);
        },

        setTransformadorB(context, value) {
            context.commit('setTransformadorB', value);
        },

        setTransformadorC(context, value) {
            context.commit('setTransformadorC', value);
        },

        setCosteLineaElectrica(context, value) {
            context.commit('setCosteLineaElectrica', value);
        },

        setValvulaHidraulicaA(context, value) {
            context.commit('setValvulaHidraulicaA', value);
        },

        setValvulaHidraulicaB(context, value) {
            context.commit('setValvulaHidraulicaB', value);
        },

        setValvulaHidraulicaC(context, value) {
            context.commit('setValvulaHidraulicaC', value);
        },

        setCosteFiltroA(context, value) {
            context.commit('setCosteFiltroA', value);
        },

        setCosteFiltroB(context, value) {
            context.commit('setCosteFiltroB', value);
        },

        setCosteFiltroC(context, value) {
            context.commit('setCosteFiltroC', value);
        },

        setVidaUtil(context, value) {
            context.commit('setVidaUtil', value);
        },

        setInteres(context, value) {
            context.commit('setInteres', value);
        },

        setTasaEnergia(context, value) {
            context.commit('setTasaEnergia', value);
        },

        setIva(context, value) {
            context.commit('setIva', value);
        },

        setTransporte(context, value) {
            context.commit('setTransporte', value);
        },
        
        setProyecto(context, value) {
            context.commit('setProyecto', value);
        },

        setTransporte(context, value) {
            context.commit('setTransporte', value);
        },

        setProyecto(context, value) {
            context.commit('setProyecto', value);
        },

        setPerforacion(context, value) {
            context.commit('setPerforacion', value);
        },

        setCableadoA(context, value) {
            context.commit('setCableadoA', value);
        },

        setCuadroA(context, value) {
            context.commit('setCuadroA', value);
        },
        setArrancadorA(context, value) {
            context.commit('setArrancadorA', value);
        },
        setCableadoB(context, value) {
            context.commit('setCableadoB', value);
        },

        setCuadroB(context, value) {
            context.commit('setCuadroB', value);
        },
        setArrancadorB(context, value) {
            context.commit('setArrancadorB', value);
        },

        setCableadoC(context, value) {
            context.commit('setCableadoC', value);
        },
        setArrancadorC(context, value) {
            context.commit('setArrancadorC', value);
        },

        setProgramadorAuxiliares(context, value) {
            context.commit('setProgramadorAuxiliares', value);
        },
        setDefaultCostObject(context, value) {
            context.commit('setDefaultCostObject', value);
        },
        setSubunitCostObject(context, value) {
            context.commit('setSubunitCostObject', value);
        },
    },
  
    mutations: {
      A(state, value) {
            state.subunitCostObject.a = value;
      },

      B(state, value) {
        state.subunitCostObject.b = value;
      },

      C(state, value) {
        state.subunitCostObject.c = value;
      },

      setCosteBombaA(state, value) {
        state.defaultCostObject.coste_bomba_a = value;
      },

      setCosteBombaB(state, value) {
        state.defaultCostObject.coste_bomba_b = value;
      },

      setCosteBombaC(state, value) {
        state.defaultCostObject.coste_bomba_c= value;
      },

      setImpulsionA(state, value) {
        state.defaultCostObject.impulsion_a = value;
      },

      setImpulsionB(state, value) {
        state.defaultCostObject.impulsion_b = value;
      },

      setSecundariaA(state, value) {
        state.defaultCostObject.secundaria_a = value;
      },

      setSecundariaB(state, value) {
        state.defaultCostObject.secundaria_b = value;
      },

      setPotenciaCosteValle(state, value) {
        state.defaultCostObject.potencia_coste_valle = value;
      },

      setPotenciaCosteLlano(state, value) {
        state.defaultCostObject.potencia_coste_llano = value;
      },

      setPotenciaCoste(state, value) {
        state.defaultCostObject.potencia_coste = value;
      },

      setEnergiaCosteValle(state, value) {
        state.defaultCostObject.energia_coste_valle = value;
      },

      setEnergiaCosteLlano(state, value) {
        state.defaultCostObject.energia_coste_llano = value;
      },

      setEnergiaCoste(state, value) {
        state.defaultCostObject.energia_coste = value;
      },

      setImpuesto(state, value) {
        state.defaultCostObject.impuesto = value;
      },

      setAlquiler(state, value) {
        state.defaultCostObject.alquiler = value;
      },

      setTransformadorA(state, value) {
        state.defaultCostObject.transformador_a = value;
      },

      setTransformadorB(state, value) {
        state.defaultCostObject.transformador_b = value;
      },

      setTransformadorC(state, value) {
        state.defaultCostObject.transformador_c = value;
      },

      setCosteLineaElectrica(state, value) {
        state.defaultCostObject.coste_linea_electrica = value;
      },

      setValvulaHidraulicaA(state, value) {
        state.defaultCostObject.valvula_hidraulica_a = value;
      },

      setValvulaHidraulicaB(state, value) {
        state.defaultCostObject.valvula_hidraulica_b = value;
      },

      setValvulaHidraulicaC(state, value) {
        state.defaultCostObject.valvula_hidraulica_c = value;
      },

      setCosteFiltroA(state, value) {
        state.defaultCostObject.coste_filtro_a = value;
      },

      setCosteFiltroB(state, value) {
        state.defaultCostObject.coste_filtro_b = value;
      },

      setCosteFiltroC(state, value) {
        state.defaultCostObject.coste_filtro_c = value;
      },

      setVidaUtil(state, value) {
        state.defaultCostObject.vida_util = value;
      },

      setInteres(state, value) {
        state.defaultCostObject.interes = value;
      },

      setTasaEnergia(state, value) {
        state.defaultCostObject.tasa_energia = value;
      },

      setIva(state, value) {
        state.defaultCostObject.iva = value;
      },
      
      setTransporte(state, value) {
        state.defaultCostObject.transporte = value;
      },

      setProyecto(state, value) {
        state.defaultCostObject.proyecto = value;
      },

      setPerforacion(state, value) {
        state.defaultCostObject.perforacion = value;
      },


      setCableadoA(state, value) {
        state.defaultCostObject.cableado_a = value;
      },
      setCuadroA(state, value) {
        state.defaultCostObject.cuadro_a = value;
      },
      setArrancadorA(state, value) {
        state.defaultCostObject.arrancador_a = value;
      },

      setCableadoB(state, value) {
        state.defaultCostObject.cableado_b = value;
      },
      setCuadroB(state, value) {
        state.defaultCostObject.cuadro_b = value;
      },
      setArrancadorB(state, value) {
        state.defaultCostObject.arr = value;
      },

      setCableadoC(state, value) {
        state.defaultCostObject.cableado_c = value;
      },
      setArrancadorC(state, value) {
        state.defaultCostObject.arrancador_c = value;
      },

      setProgramadorAuxiliares(state, value) {
        state.defaultCostObject.programador_auxiliares = value;
      },
      setDefaultCostObject(state, value) {
        state.defaultCostObject = value;
      },
      setSubunitCostObject(state, value) {
        state.subunitCostObject = value;
      },

      
      
    },
};
  