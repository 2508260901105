import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Modules
import parametrosHidraulicos from './modules/parametrosHidraulicos';
import caracteristicasBomba from './modules/caracteristicasBomba';
import costesInversion from './modules/costesInversion';
import horasMensuales from './modules/horasMensuales';
import authentication from './modules/authentication';


Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    parametrosHidraulicos,
    caracteristicasBomba,
    costesInversion,
    horasMensuales,
    authentication
  },
  plugins: [new VuexPersistence({
    storage: window.localStorage,
  }).plugin],
});

export default store;
