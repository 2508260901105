<template>
    <f7-page name="resultadosTeoricos" class="fondo-pantalla-completa" v-if="loaded">
        <f7-block >
            <f7-row>
               <f7-col>
                  <f7-button fill  class="boton-standar" href='/resultadosTeoricos/'>{{ $t('PreviousText') }}</f7-button>
               </f7-col>
            </f7-row>
         </f7-block>
        <div class="" style="margin: auto; width: 50%; padding: 10px;">
            <graphicCurvascarac :responseCurvasCarac="responseCurvasCarac"/>
        </div>
        <div>
        </div>
        <div class="" style="margin: auto; width: 50%; padding: 10px;">
            <graphicCurvascarac2 :responseCurvasCarac="responseCurvasCarac"/>
        </div>
    </f7-page>
</template>

<script>
import Api from '../js/Services/Api.js';
import graphicCurvascarac from '../components/graphicCurvascarac.vue';
import graphicCurvascarac2 from '../components/graphicCurvascarac2.vue';
import { mapState } from 'vuex'

 export default {
    components: {
      graphicCurvascarac,
      graphicCurvascarac2
   },
     data() {
         return {
            responseCurvasCarac: {},
            loaded: false,
        };
     },
     	computed: {
    ...mapState('caracteristicasBomba', ['dopirModelOptimizer']),
    ...mapState('parametrosHidraulicos', ['qNominal']),
  },
     async beforeMount(){
      this.$f7.preloader.show();
      try {
        const newCaracteristicas = {
            Q: this.qNominal,
            coefa: this.dopirModelOptimizer.coeficienteA,
            coefc: this.dopirModelOptimizer.coeficienteC,
            coefd: this.dopirModelOptimizer.coeficienteD,
            coefe: this.dopirModelOptimizer.coeficienteE,
				};

			const respuesta = await Api.curvasCarac(newCaracteristicas);
         this.responseCurvasCarac = JSON.parse(respuesta.response);
      } catch(e) {
         console.error(e)
      } finally {
         this.$f7.preloader.hide()
         this.loaded = true;
      }
     }

 };
</script>
<style>
.caja-volumen {
  border: 1px;
  height: 300px;
  width: 100%;
  margin-top: -2%;
}
</style>
