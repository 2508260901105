<template>
  <f7-block class="no-padding">
    <DxSelectBox
      id="custom-templates"
      :data-source="languages"
      :value="languageSelected"
      display-expr="name"
      value-expr="id"
      field-template="field"
      item-template="item"
      style="width: 150px"
      @value-changed="changeLanguage"
    >
      <template #field="{ data: languages }">
        <div class="custom-item">
          <span
            style="left: 85%; position: absolute; top: 50%; margin-top: -8px"
          >
            {{ languages.icon }}
          </span>
          <DxTextBox
            :value="$t(languages.name)"
            :read-only="true"
            class="product-name"
            style="padding-left: 0.5rem;"
          />
        </div>
      </template>
      <template #item="{ data: languages }">
        {{ languages.icon }} {{ $t(languages.name) }}
      </template>
    </DxSelectBox>
  </f7-block>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';

export default {
  name: 'SelectLanguage',
  components: {
    DxSelectBox,
    DxTextBox,
  },
  props: {
    languageSelected: {
      type: String,
      default: '',
    },
    languages: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    changeLanguage(e) {
      this.$emit('changeLanguage', e.value);
    },
  },
};
</script>
